import React from "react";

export default function Loader() {
  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
          zIndex: 3,
        }}
      >
        <div style={{ color: "#B81A8E", width: 50, height: 50 }} className="spinner-border" role="status"></div>
      </div>
    </div>
  );
}
