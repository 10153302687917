import React from "react";

import moment from "moment";
import Table from "../../components/table/table";

export default function CompetenceslistPage() {
  const columns = [
    {
      accessorKey: "name",
      header: "Nom",
    },
    {
      accessorKey: "secteur.name",
      header: "Secteur",
    }
  ];
  return <Table dataUrl="competences" columns={columns} permission="competences" title="Compétences" />;
}
