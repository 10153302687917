/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import Label from "../../label/label";
import "./select-input.css";
import Select from "react-select";

import { useController, useFormContext, FieldValues, UseControllerReturn } from "react-hook-form";
const SelectInput = SelectInputController((props: SelectInputType) => {
  const [selected, setSelected]: any = useState(!props.multiple ? "" : []);
  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    setOptions(props.options);
    if (typeof props.value == "string" && !props.multiple) {
      const item: any = props.options.find((elt: any) => elt.value == props.value);
      props.controller?.field?.onChange(props.value);
      setSelected(item);
    }
    if (Array.isArray(props.value) && props.multiple) {
      const items: any = props.options.filter((elt: any) => (props?.value as any).find((val: any) => val == elt.value));
      props.controller?.field?.onChange(items.map((elt: any) => elt.value));
      setSelected(items);
    }
  }, [props.value, props.options, props.multiple]);

  function onBlur() {
    setOptions(props.options);
    props.controller?.field?.onBlur();
  }

  function onChange(item: any) {
    if (!props.multiple) {
      props.controller?.field?.onChange(item?.value || item);
      props.controller?.field?.onBlur();
      setSelected(item);
      if (props.onChange) {
        props.onChange(item);
      }
    } else {
      setSelected(item);
      props.controller?.field?.onChange(item.map((elt: any) => elt.value));
      props.controller?.field?.onBlur();
      if (props.onChange) {
        props.onChange(item);
      }
    }
  }
  const error = props.controller?.fieldState?.invalid;
  const errorMSg = props.controller?.fieldState?.error?.message;
  return (
    <div
      style={props.containerStyle}
      className={`input-container ${props.inline ? "row" : ""} ${props.containerClass || ""}`}
    >
      <Label
        label={props.label}
        for={props.inputId}
        labelIcon={props.labelIcon}
        labelStyle={props.labelStyle}
        labelClass={props.labelClass}
      />
      <Select
        classNames={{
          control: (state) =>
            (state.isFocused ? "form-control formfocus " : "form-control") + (error ? " form-control-invalid" : ""),
        }}
        value={selected}
        onBlur={onBlur}
        isDisabled={props.disabled}
        closeMenuOnSelect={!props.multiple}
        onChange={onChange}
        isClearable={props.isClearable}
        styles={{
          control: () => ({ padding: 0, display: "flex" }),
          option: (styles, { isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#B81A8E" : isFocused ? "#fce4ec" : "white",
              color: isSelected ? "white" : "black",
            };
          },
          multiValue: (styles, { isDisabled }) => {
            return {
              ...styles,
              color: "white",
              backgroundColor: isDisabled ? "gray" : "#B81A8E",
              paddingTop: 3,
              paddingBottom: 3,
              borderRadius: 6,
            };
          },
        }}
        isMulti={props.multiple}
        isSearchable={props.searchable}
        options={options}
      />
      {error && (
        <div className={`form-error-msg`}>
          <img
            style={{ marginInlineEnd: 5, marginTop: -3, marginRight: 5, width: 14, height: 14 }}
            src="/images/error.svg"
            alt=""
          />
          {errorMSg}
        </div>
      )}
    </div>
  );
});
export default SelectInput;
function SelectInputController(Input: any) {
  return function (props: SelectInputType) {
    return SelectControl(Input, props);
  };
}
export function SelectControl(Input: any, p: SelectInputType) {
  const formContext = useFormContext();
  let controller: any;
  if (p.name && formContext)
    controller = useController({
      name: p.name || "",
      rules: {
        validate: (val: any) => {
          let res: any = true;
          if (p.required) {
            if (p.multiple) res = val && val.length > 0 ? true : p.required;
            else res = val != undefined && val != "" ? true : p.required;
          }
          if (p.multiple && p.minSelected && val?.length < p.minSelected) res = false;
          return res;
        },
      },
      defaultValue:
        !p.multiple && p.value && !Array.isArray(p.value)
          ? p.value
          : Array.isArray(p.value) && p.multiple
          ? p?.value?.filter((elt: any) => (p.options as any).find((elt1: any) => elt1.value == elt || elt1 == elt))
          : !p.multiple
          ? ""
          : [],
    });
  return <Input {...p} controller={controller} />;
}
export interface SelectInputType {
  label?: string; //done
  labelIcon?: string | { className: string; style?: React.CSSProperties };
  labelClass?: string; //done
  placeHolder?: string;
  labelStyle?: React.CSSProperties; //done
  value?: string | string[]; //done
  inputClass?: string; //dne
  inputStyle?: React.CSSProperties; //done
  inputId?: string; //done
  errorMsg?: boolean;
  isClearable?: boolean;
  listonly?: boolean;
  form?: string;
  controller?: UseControllerReturn<FieldValues, string>;
  errorMode?: "tooltip" | "feedback";
  name?: string;
  allowClear?: boolean;
  minSelected?: number;
  maxSelected?: number;
  multiple?: boolean;
  disabled?: boolean; //done
  required?: boolean | string;
  itemRender?: any;
  inline?: boolean;
  options: Array<typeOption>;
  containerClass?: string; //done
  optionClass?: string;
  containerStyle?: React.CSSProperties; //done
  optionStyle?: React.CSSProperties;
  optionGroupStyle?: React.CSSProperties;
  optionGroupClass?: string;
  onChange?: Function;
  searchable?: boolean;
}
interface typeOption {
  value: string | number;
  label?: string;
  disabled?: boolean;
  fixed?: boolean;
}
