import moment from "moment";
import React from "react";
import Table from "../../../components/table/table";
import { statusUser } from "../../../utils/utils";

export default function EmployeelistPage() {
  const columns = [
    {
      accessorKey: "status",
      header: "Statut",
      filterVariant: "multi-select",
      filterSelectOptions: [
        { label: "Validé", value: "valid" },
        { label: "En attente", value: "inscrit" },
        { label: "Inactive", value: "inactive" },
      ],
      size: 30,
      Cell: ({ cell }: any) => {
        const tag = statusUser(cell.row.original.status);
        return <span className={"badge " + tag.class}>{tag.title}</span>;
      },
    },
    {
      accessorKey: "email",
      header: "E-mail",
    },
    {
      accessorKey: "prenom",
      header: "Prénom",
    },
    {
      accessorKey: "nom",
      header: "Nom",
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.created_at),
      header: "Date de création",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
  ];
  return <Table dataUrl="users/employee" columns={columns} permission="employees" title="Employées" />;
}
