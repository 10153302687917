import React from "react";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { showToast } from "../../utils/utils";
import { useAppContext } from "../../context/app.context";
import Button from "../../components/form/button/button";
import { FormProvider, useForm } from "react-hook-form";
import "./login.page.css";
export default function LoginPage() {
  const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const { setAppInfos } = useAppContext();
  const navigate = useNavigate();
  const methods = useForm();

  async function onSubmit(form: any) {
    try {
      const { data } = await axiosInstance.post("auth/signin", form);
      if (data.user.role != "admin") return showToast("Les informations de connexion sont incorrectes");
      setAppInfos({
        token: data.user.token,
        user: { ...data.user },
      });
      navigate("/");
    } catch (e: any) {
      if (e?.response?.status == 422) showToast("Les informations de connexion sont incorrectes");
    }
  }

  return (
    <div className="login row align-items-center">
      <div className="col center mb-3">
        <img className="card logo" alt="logo" src="/images/logo.png" />
      </div>
      <div className="col center">
        <div className="p-5 card loginform">
          <h4 className="text-center mb-5">Connexion</h4>
          <FormProvider {...methods}>
            <TextInput
              validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
              name="email"
              autocomplete="off"
              type="email"
              placeHolder="Votre email"
              label="Adresse e-mail"
            />
            <TextInput
              autocomplete="off"
              showPassword
              name="password"
              type="password"
              required="Mot de passe invalide"
              placeHolder="Votre mot de passe"
              label="Mot de passe"
            />
            <Button onClick={methods.handleSubmit(onSubmit)} title="login" />
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
