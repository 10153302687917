import moment from "moment";
import React from "react";
import Table from "../../components/table/table";
import Button from "../../components/form/button/button";
import { useNavigate, useSearchParams } from "react-router-dom";
export function statusMission(status: any) {
  if (status == "enattente") return { title: "En attente", class: "badge-warning" };
  if (status == "annulee") return { title: "Annulée", class: "badge-danger" };
  if (status == "terminée") return { title: "Disponible", class: "badge-success" };
  return { title: "", class: "" };
}
export default function MissionslistPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mission = searchParams.get("mission");
  const columns = [
    {
      accessorKey: "status",
      header: "Statut",
      filterVariant: "multi-select",
      filterSelectOptions: [
        { label: "Disponible", value: "terminée" },
        { label: "En attente", value: "enattente" },
        { label: "Annulée", value: "annulee" },
      ],
      size: 30,
      Cell: ({ cell }: any) => {
        const tag = statusMission(cell.row?.original?.status);
        return <span className={"badge " + tag.class}>{tag.title}</span>;
      },
    },
    {
      accessorKey: "type",
      header: "Type",
      size: 30,
    },
    {
      accessorKey: "numFacture",
      header: "Numéro",
      size: 30,
    },
    {
      accessorKey: "emetteur",
      header: "Émetteur",
      size: 30,
    },
    {
      accessorKey: "recepteur",
      header: "Adressé à",
      size: 30,
    },
    {
      accessorKey: "priceHT",
      header: "prix HT",
      accessorFn: (originalRow: any) =>originalRow?.priceHT && !isNaN(+originalRow?.priceHT)?(+originalRow?.priceHT)?.toFixed(2):'',
      size: 30,
    },
    {
      accessorKey: "priceTTC",
      accessorFn: (originalRow: any) => originalRow?.priceTTC && !isNaN(+originalRow?.priceTTC)?(+originalRow?.priceTTC)?.toFixed(2):'',
      header: "prix TTC",
      size: 30,
    },
    {
      accessorKey: "tvapercent",
      header: "TVA(%)",
      size: 30,
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.facturationDate),
      header: "Date de facturation",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(+cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.created_at),
      header: "Date de création",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(+cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
    {
      accessorKey: "mission",
      header: "Mission",
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: any) => {
        const id = cell?.row?.original?.idMission?._id;
        return (
          <Button
            onClick={() => {
              navigate("/missions/" + id);
            }}
            disabled={!id}
            className="btn btn-secondary btnsmall"
            title="Voir"
          />
        );
      },
    },
  ];
  function mapData(elt: any) {
    if (elt.idJobbeur) elt.idJobbeur.nom = (elt?.idJobbeur?.prenom || "") + " " + (elt?.idJobbeur?.nom || "");
    elt.emetteur =
      elt.type == "commission"
        ? "SAS ARÈS"
        : elt.type == "rémunération"
        ? elt?.idJobbeur?.nom
        : elt?.idEntreprise?.NomEntreprise;
    elt.recepteur =
      elt.type == "commission"
        ? elt.idEntreprise?.NomEntreprise
        : elt.type == "rémunération"
        ? elt?.idEntreprise?.NomEntreprise
        : elt?.idJobbeur?.nom;
    if (elt.manually) elt.status = "terminée";
    else {
      const mission = elt.idMission;
      if (mission.status == "annulee") {
        elt.status = "annulee";
      } else if (mission.idJobbeur === null && moment(mission.dateFin).isBefore(new Date())) {
        elt.status = "annulee";
      } else if (mission.status === "enattente" && moment(mission.dateDebut).isAfter(new Date())) {
        elt.status = "enattente";
      } else if (
        elt?.idJobbeur?._id &&
        moment(mission.dateDebut).isBefore(new Date()) &&
        moment(mission.dateFin).isAfter(new Date())
      ) {
        elt.status = "enattente";
      } else if (
        !mission?.idJobbeur?._id &&
        moment(mission.dateDebut).isBefore(new Date()) &&
        moment(mission.dateFin).isAfter(new Date())
      ) {
        elt.status = "annulee";
      } else if (moment(mission.dateDebut).isAfter(new Date())) {
        elt.status = "enattente";
      } else if (moment(mission.dateFin).isBefore(new Date())) elt.status = "terminée";
      else if (mission.status) elt.status = "annulee";
    }
    return elt;
  }
  return (
    <Table
      mapData={mapData}
      facture={true}
      dataUrl={"factures" + (mission ? "?mission=" + mission : "")}
      columns={columns}
      permission="factures"
      title="Factures"
    />
  );
}
