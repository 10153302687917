import React, { useEffect, useState } from "react";
import { Modal as Mmodal } from "react-responsive-modal";
import "./modal.css";
import Button from "../form/button/button";
export default function Modal(props: {
  show: boolean;
  title: string;
  description?: string;
  btnconfirm?: string;
  btnclose?: string;
  onConfirm?: Function;
  onClose?: Function;
}) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);
  return (
    <Mmodal
      closeIcon={<img  width={28} src="/images/close.svg" alt="" />}
      styles={{
        modal: {
          borderRadius: 8,
          minHeight: 245,
          minWidth: "30vw",
          maxWidth: 350,
          padding: 25,
        },
      }}
      open={open}
      onClose={() => {
        setOpen(false);
        if (props.onClose) props.onClose();
      }}
      center
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h4 style={{marginTop:15}}>{props.title}</h4>
        <h6 style={{ textAlign: "center" }}>{props.description}</h6>
        <Button className="btn-primary mb-3 mt-3" onClick={props.onConfirm} title={props.btnconfirm || "Confirmer"} />
        <Button className="btn-secondary" onClick={props.onClose} title={props.btnclose || "Annuler"} />
      </div>
    </Mmodal>
  );
}
