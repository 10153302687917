import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../form/button/button";
import Loader from "../loader/loader";
import "./formlayout.css";
import { capitalize } from "lodash";
import { useAppContext } from "../../context/app.context";
import Page404 from "../../pages/404/404.page";
import { useNavigate } from "react-router-dom";
import Modal from "../modal/modal";

// eslint-disable-next-line prettier/prettier
export default function FormLayout(props: { onSubmit?: Function; children?: any; loading?: boolean; title?:string,img?:string,
  permission?: any;
  isAdd?: boolean;
  notFound?: boolean;
  secondtitle?:any;
  onSubmitSecond?: Function;
}) {
  const methods = useForm({ mode: "onBlur" });
  const isDirty = methods?.formState?.isDirty;
  const formValue = useRef({});
  const { appInfos } = useAppContext();
  const navigate = useNavigate();
  const emplyee = props.permission && appInfos.user.type == "Employee";
  const perms = appInfos.user?.permissions && appInfos.user?.permissions[props.permission];
  const [openmodal, setopenmodal] = useState(false);
  const readOnly = props.isAdd
    ? emplyee && !perms?.find((elt: any) => elt == "add")
    : emplyee && !perms?.find((elt: any) => elt == "edit");
  return props.loading ? (
    <Loader />
  ) : props.notFound ? (
    <Page404 />
  ) : (
    <div className="formlayout">
      <Modal
        show={openmodal}
        title={props.isAdd ? "Confirmer l'ajout" : "Confirmer la modification"}
        description={
          props.isAdd
            ? "Êtes-vous sûr de vouloir ajouter cet élément ? "
            : "Êtes-vous sûr de vouloir modifier cet élément ? "
        }
        onClose={() => {
          setopenmodal(false);
        }}
        onConfirm={async () => {
          if (props.onSubmit) await props.onSubmit(formValue.current);
        }}
      />
      <FormProvider {...methods}>
        <div className="card formcard">
          <div className="formcontent">
            <h4>
              <span onClick={() => navigate(-1)}>
                <img style={{ marginRight: 10, marginTop: -4 }} width={32} alt="" src={"/images/left-arrow.svg"} />
              </span>
              {/* {props.img ? <img style={{ marginRight: 10, marginTop: -4 }} width={30} alt="" src={props.img} /> : null} */}
              {readOnly ? capitalize(props.title) : (props.isAdd ? "Ajout" : "Modification") + " " + props.title}
            </h4>
            <div style={{ flexGrow: 1, display: "flex" }}>
              <div className="formbody" style={{ pointerEvents: readOnly ? "none" : "auto" }}>
                {props.children}
              </div>
            </div>
          </div>
          <div className="formbtn">
            <div className="add" style={{ opacity: readOnly ? 0.4 : 1 }}>
              {!readOnly && (
                <Button
                  onClick={methods.handleSubmit((form) => {
                    formValue.current = form;
                    setopenmodal(true);
                  })}
                  disabled={!isDirty}
                  title={"Confirmer"}
                  className="btn-primary"
                />
              )}
            </div>
            {props.onSubmitSecond && (
              <div className="add " style={{ opacity: readOnly ? 0.4 : 1, marginLeft: 20 }}>
                {!readOnly && (
                  <Button
                    onClick={props.onSubmitSecond}
                    disabled={!isDirty}
                    title={props.secondtitle}
                    className="btn-secondary"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
}
