import { toast } from "react-toastify";

export function formatPhone(text: any) {
  const cleanedInput = text?.replace(/\D/g, "");
  if (cleanedInput) {
    const formattedText = cleanedInput.match(/.{1,2}/g).join(".");
    return formattedText;
  } else return "";
}

export function formatCode(inputText: any) {
  const cleanedInput = inputText.replace(/\D/g, "");
  const formattedText = cleanedInput.split("").join(" ");
  return formattedText;
}

export const showToast = (content: string, type?: any, position?: any) =>
  toast(content, { autoClose: 2500, type: type || "error", position, toastId: "toast" });

export function chooseFile(props?: { multiple?: boolean; patternFile?: string[] }) {
  let fileInput: any = document.getElementById("global-input-file");
  if (!fileInput) {
    fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.id = "global-input-file";
    fileInput.style = "display:none";
    document.body.appendChild(fileInput);
  }
  return new Promise((resolve) => {
    fileInput.multiple = props?.multiple || false;
    fileInput.accept = "";
    if (props?.patternFile)
      fileInput.accept = props.patternFile?.reduce((res, current) => {
        return res + current + ",";
      }, "");
    const onChange = (e: any) => {
      resolve(e.target.files);
      fileInput.removeEventListener("change", onChange);
      document.body.removeChild(fileInput);
    };
    fileInput.addEventListener("change", onChange);
    fileInput.click();
  });
}
export async function readLocalFile(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (e) {
      reject(e);
    };
  });
}

export function statusUser(status: any) {
  if (status == "valide") return { title: "Validé", class: "badge-success" };
  if (status == "inscrit") return { title: "En attente", class: "badge-warning" };
  if (status == "inactive") return { title: "Inactive", class: "badge-danger" };
  if (status == "suspendu") return { title: "Suspendu", class: "badge-danger" };
  return { title: "", class: "" };
}

export const EntrepriseTypes = ["SAS", "SA", "SARL", "SASU", "SNC", "SCS", "SCA", "EURL", "EL"].map((elt) => ({
  label: elt,
  value: elt,
}));
