/* eslint-disable no-empty */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import "./table.css";
import Button from "../form/button/button";
import "react-responsive-modal/styles.css";
import Modal from "../modal/modal";
import { Link, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../context/app.context";
import axiosInstance from "../../config/axios";
import CsvDownloader from "react-csv-downloader";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

export default function Table(props: {
  title?: string;
  columns?: any;
  data?: any[];
  isLoading?: boolean;
  isSaving?: boolean;
  onRemove?: Function;
  onExport?: Function;
  onEdit?: Function;
  onAdd?: Function;
  hideAdd?: boolean;
  hideDelete?: boolean;
  hideEdit?: boolean;
  showExport?: boolean;
  deleteTitle?: string;
  csvRows?: any[];
  deleteDesc?: string;
  permission?: any;
  dataUrl?: any;
  facture?: boolean;
  csvDataFn?: Function;
  mapData?: any;
}) {
  const [openmodal, setopenmodal] = useState(false);
  const [selectedrow, setselectedrow] = useState("");
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving] = useState(props.isSaving);
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [data, setdata] = useState(props.data);
  const { appInfos } = useAppContext();
  const emplyee = props.permission && appInfos.user.type == "Employee";
  const perms = appInfos.user?.permissions && appInfos.user?.permissions[props.permission];
  const disableAdd = emplyee && !perms?.find((elt: any) => elt == "add");
  const disableEdit = emplyee && !perms?.find((elt: any) => elt == "edit");
  const disableDelete = emplyee && !perms?.find((elt: any) => elt == "delete");

  useEffect(() => {
    if (props.dataUrl) {
      (async () => {
        setIsLoading(true);
        try {
          const result = await axiosInstance.get(props.dataUrl);
          let d = result.data.data || result.data;
          if (props.mapData) {
            d = d.map((elt: any) => props?.mapData(elt));
          }
          setdata(d);
        } catch (e) {}
        setIsLoading(false);
        const columnFiltersParam = searchParams.getAll("columnFilters[]");
        if (columnFiltersParam.length > 0) {
          setColumnFilters(columnFiltersParam.map((filter) => JSON.parse(filter)));
        }

        const globalFilterParam = searchParams.get("globalFilter");
        if (globalFilterParam) {
          setGlobalFilter(globalFilterParam);
        }
        const sortingParam = searchParams.getAll("sorting[]");
        if (sortingParam.length > 0) {
          setSorting(sortingParam.map((sort) => JSON.parse(sort)));
        }
        //   const pageIndexParam = searchParams.get("pagination[pageIndex]");
        //   const pageSizeParam = searchParams.get("pagination[pageSize]");
        // setTimeout(() => {
        //   setPagination({
        //     pageIndex: pageIndexParam ? Number(pageIndexParam) : 0,
        //     pageSize: pageSizeParam ? Number(pageSizeParam) : 10,
        //   });
        // }, 50);
      })();
    } else setdata(props.data);
  }, []);

  function onRemove(row: any) {
    setselectedrow(row._id || row.id);
    setopenmodal(true);
  }

  async function onConfirmRemove() {
    setIsSaving(true);
    if (!props.onRemove)
      try {
        await axiosInstance.delete(props.dataUrl + "/" + selectedrow);
      } catch (e) {}
    else await props.onRemove(selectedrow);
    setIsSaving(false);
    setdata(data?.filter((elt) => (elt._id || elt.id) != selectedrow));
    setopenmodal(false);
    setselectedrow("");
  }
  function getCSVRows() {
    console.log("hello", JSON.stringify(props.csvRows, null, 4));
    if (props.csvRows) return props.csvRows;
    let cls = [{ id: "_id", displayName: "ID" }, ...props.columns];
    cls = cls
      .filter((elt: any) => (elt.id || elt.accessorKey) != undefined && !elt.nocsv)
      .map((elt: any) => ({ key: elt.id || elt.accessorKey, label: elt.header }));
    console.log("pppppprows", cls);
    return cls;
  }
  function getCSVData() {
    const csvData: any = table.getFilteredRowModel().rows.length
      ? table.getFilteredRowModel().rows.map((elt: any) => {
          const original: any = { ...elt.original };
          Object.keys(original).forEach((key) => {
            if (typeof original[key] == "boolean") original[key] = original[key] ? "Oui" : "Non";
            else if (!isNaN(original[key])) original[key] = original[key] + "";
            else if (original[key] && !isNaN(new Date(original[key])?.getTime()))
              original[key] = moment(original[key]).format("DD/MM/YYYY");
            else if (original[key]?.name) original[key] = original[key].name;
            else if (Array.isArray(original[key]) && original[key][0]?.name)
              original[key] = original[key].map((elt: any) => elt?.name).join(" ,");
            else original[key] = original[key].toString();
          });
          return original;
        })
      : props.data;
    console.log("ppppppcsvdata", csvData);
    return csvData;
  }
  const toSearchParams = (obj: any) => {
    const newParams = new URLSearchParams();
    if (obj.sorting) {
      obj.sorting.forEach((sort: any) => {
        newParams.append("sorting[]", JSON.stringify(sort));
      });
    }
    if (obj.columnFilters) {
      obj.columnFilters.forEach((filter: any) => {
        newParams.append("columnFilters[]", JSON.stringify(filter));
      });
    }
    if (obj.pagination) {
      for (const [key, value] of Object.entries(obj.pagination)) {
        newParams.set(`pagination[${key}]`, value as any);
      }
    }

    return newParams;
  };

  useEffect(() => {
    setSearchParams(
      toSearchParams({
        sorting,
        columnFilters,
        pagination,
      }),
    );
  }, [sorting, columnFilters, pagination]);
  const table = useMaterialReactTable({
    columns: props.columns,
    data: data || [],
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    state: {
      isLoading: isLoading,
      isSaving: isSaving,
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },

    localization: MRT_Localization_FR,
    enableRowNumbers: true,
    enableStickyHeader: true,
    enableStickyFooter: true,

    enableColumnFilters: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionActionsColumn: "first",
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableToolbarInternalActions: true,
    enableRowActions: true,
    //enableRowOrdering:true,
    // isMultiSortEvent: () => true,
    // enableMultiRowSelection: true,
    // enableColumnPinning: true,
    // enableColumnOrdering: true,
    // selectAllMode: "all",
    // enableRowSelection: true,
    // enableBatchRowSelection: true,
    renderRowActions: (p) => (
      <div style={{ display: "flex" }}>
        {!props.hideEdit && (
          <Link to={(p.row.original._id || p.row.original.id) + ""}>
            <span className="action">
              <img src={!disableEdit ? "/images/edit.svg" : "/images/eye.svg"} alt="modifier" />
            </span>
          </Link>
        )}
        {!props.hideDelete && (
          <span
            style={{ pointerEvents: disableDelete ? "none" : "auto", opacity: disableDelete ? 0.2 : 1 }}
            className="action"
            onClick={() => onRemove(p.row.original)}
          >
            <img style={{ marginLeft: 10 }} src="/images/remove.svg" alt="supprimer" />
          </span>
        )}
        {props.facture && (
          <a
            href={
              process.env.REACT_APP_SERVER_URL +
              "/api/factures/facturepdf/" +
              (p.row.original._id || p.row.original.id) +
              "?token=" +
              appInfos.token
            }
          >
            <span className="action">
              <img style={{ marginLeft: 10 }} src="/images/download.svg" alt="supprimer" />
            </span>
          </a>
        )}
      </div>
    ),

    muiTableBodyRowProps: ({ staticRowIndex }) => ({
      className: staticRowIndex % 2 == 0 ? "rowpair" : "rowimpair",
    }),
    enableColumnActions: false,
  });
  const state = table.getState();
  console.log(getCSVData());
  return (
    <div className="apptable">
      <div className="row tabletop">
        <div className="col">
          <h4 style={{ fontWeight: 600 }} className="subtitle">
            {props.title}
            {data && <span className="indicator">{data.length}</span>}
          </h4>
          {state.globalFilter || state.columnFilters?.length ? (
            <span className="legend">
              {table.getFilteredRowModel().rows.length} {props.title?.toLowerCase()} trouvés
            </span>
          ) : null}
        </div>
        {props.showExport && (
          <div className="col-auto">
            {!props.onExport ? (
              <>
                <CSVLink
                  filename={(props.title?.toLowerCase() || "list")+".csv"}
                  className="button btn-secondary addbtn"
                  data={getCSVData() || []}
                  headers={getCSVRows()}
                >
                  Télecharger la liste
                </CSVLink>
              </>
            ) : (
              <Button onClick={props.onExport} className="btn-secondary addbtn" title="Télecharger la liste" />
            )}
          </div>
        )}
        {!props.hideAdd && (
          <div className="col-auto ">
            <Link style={{ pointerEvents: disableAdd ? "none" : "auto" }} to={disableAdd ? (undefined as any) : "add"}>
              <Button
                style={{ pointerEvents: disableAdd ? "none" : "auto", opacity: disableAdd ? 0.2 : 1 }}
                className="btn-primary addbtn"
                title="Ajouter"
              />
            </Link>
            <Link className="addsm" to="add">
              <img width={32} height={32} src="/images/plus.svg" alt="modifier" />
            </Link>
          </div>
        )}
      </div>
      <MaterialReactTable table={table} />
      <Modal
        show={openmodal}
        title={props.deleteTitle || "Confirmer la Suppression"}
        description={
          props.deleteDesc ||
          "Êtes-vous sûr de vouloir supprimer cet élément ? Cette action est irréversible et entraînera la perte définitive de toutes les données associées. "
        }
        onClose={() => {
          setopenmodal(false);
          setselectedrow("");
        }}
        onConfirm={onConfirmRemove}
      />
    </div>
  );
}
