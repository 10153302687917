import React, { useEffect, useRef, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";

const Map = (props: { coordinates: { lat: number; lng: number }[] }) => {
  const API_KEY: any = process.env.REACT_APP_MAP_KEY;
  const { isLoaded } = useLoadScript({ googleMapsApiKey: API_KEY });
  const mapRef = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const calculateBounds = (coords: { lat: number; lng: number }[]) => {
    const bounds = new google.maps.LatLngBounds();
    coords.forEach((coord) => bounds.extend(new google.maps.LatLng(coord.lat, coord.lng)));
    return bounds;
  };

  useEffect(() => {
    if (isLoaded && mapRef.current && !map) {
      const center = calculateBounds(props.coordinates).getCenter();
      const newMap = new window.google.maps.Map(mapRef.current, {
        center: center,
        zoom: 10.6,
      });
      setMap(newMap);
    }
  }, [isLoaded, map, props.coordinates]);

  useEffect(() => {
    if (map && props.coordinates.length > 0) {
      const polygon = new google.maps.Polygon({
        paths: props.coordinates.map((coord) => ({ lat: coord.lat, lng: coord.lng })),
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
      polygon.setMap(map);
      const bounds = calculateBounds(props.coordinates);
      map.fitBounds(bounds);
      return () => polygon.setMap(null);
    }
  }, [map, props.coordinates]);

  return (
    <div style={{ padding: 30 }}>
      <div ref={mapRef} style={{ height: "50dvh" }} />
    </div>
  );
};

export default Map;
