import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "../../../components/table/table";
import { statusUser } from "../../../utils/utils";
import Button from "../../../components/form/button/button";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../config/axios";
import Tooltip from "@mui/material/Tooltip";
export default function JobbeurslistPage() {
  const [secteurs, setsecteurs] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const csvRows = [
    { key: "status", label: "Statut" },
    { key: "email", label: "E-mail" },
    { key: "prenom", label: "Prénom" },
    { key: "nom", label: "Nom" },
    { key: "tel", label: "Téléphone" },
    { key: "siret", label: "SIRET" },
    { key: "collectTVA", label: "Collecte TVA" },
    { key: "iban", label: "IBAN" },
    { key: "created_at", label: "Date d'inscription" },
    { key: "birthday", label: "Date de naissance" },
    { key: "codePostal", label: "Code postale" },
    { key: "seuleVille", label: "Ville" },
    { key: "adresse", label: "Adresse" },
    { key: "secteur", label: "Secteur" },
    { key: "langues", label: "Langues" },
    { key: "metiers", label: "Métiers" },
  ];
  const columns = [
    {
      accessorKey: "status",
      header: "Statut",
      filterVariant: "multi-select",
      filterSelectOptions: [
        { label: "Validé", value: "valid" },
        { label: "En attente", value: "inscrit" },
        { label: "Inactive", value: "inactive" },
        { label: "Suspendu", value: "suspendu" },
      ],
      size: 30,
      Cell: ({ cell }: any) => {
        const tag = statusUser(cell.row.original.status);
        return <span className={"badge " + tag.class}>{tag.title}</span>;
      },
    },
    {
      accessorKey: "email",
      header: "E-mail",
    },
    {
      accessorKey: "prenom",
      header: "Prénom",
      size: 60,
    },
    {
      accessorKey: "nom",
      header: "Nom",
      size: 60,
    },
    {
      accessorKey: "secteur",
      accessorFn: (originalRow: any) => {
        return originalRow?.secteur?.name || "";
      },
      header: "Secteur",
      filterVariant: "multi-select",
      filterSelectOptions: secteurs,
      size: 250,
    },
    {
      id: "metiers",
      accessorFn: (originalRow: any) => {
        return originalRow.metiers.map((elt: any) => elt?.name).join(" ,");
      },
      header: "Métiers",
      Cell: ({ cell }: any) => (
        <Tooltip title={cell.getValue()}>
          <span>
            {cell.getValue().split(",")[0]}{" "}
            {cell.getValue().split(",")?.length > 1 ? "+" + (cell.getValue().split(",")?.length - 1) : ""}
          </span>
        </Tooltip>
      ),
    },
    {
      id: "created_at",
      accessorFn: (originalRow: any) => new Date(originalRow.created_at),
      header: "Date de création",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
      size: 40,
    },
    {
      accessorKey: "adresse",
      header: "Adresse",
      size: 250,
    },
    {
      accessorKey: "mission",
      header: "Missions",
      enableColumnFilter: false,
      nocsv: true,
      enableSorting: false,
      Cell: ({ cell }: any) => {
        const id = cell.row.original._id;
        return (
          <Button
            onClick={() => {
              navigate("/missions?jobbeur=" + id);
            }}
            className="btn btn-secondary btnsmall"
            title="Voir"
          />
        );
      },
    },
  ];
  useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const secteurs = (await axiosInstance.get("secteurs")).data.map((elt: any) => ({
          value: elt.name,
          label: elt.name,
        }));

        setsecteurs(secteurs);
      } catch (e) {}
      setloading(false);
    })();
  }, []);
  return (
    <Table
      mapData={(elt: any) => {
        if (elt.inactiveTill)
          if (elt.inactiveTill && new Date(elt.inactiveTill).getTime() > new Date().getTime()) {
            elt.status = "suspendu";
          }
        return elt;
      }}
      csvRows={csvRows}
      showExport
      isLoading={loading}
      dataUrl="users/jobbeur"
      columns={columns}
      permission="jobbeurs"
      title="Jobbeurs"
    />
  );
}
