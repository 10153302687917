import moment from "moment";
import React from "react";
import Table from "../../components/table/table";

export default function ZoneslistPage() {
  const columns = [
    {
      accessorKey: "name",
      header: "Nom",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorKey: "commission",
      header: "Commission",
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.created_at),
      header: "Date de création",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
  ];
  return <Table dataUrl="zones" columns={columns} permission="zones" title="Zones" />;
}
