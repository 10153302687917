import moment from "moment";
import React from "react";
import Table from "../../components/table/table";

export default function ContactslistPage() {
  const columns = [
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "subject",
      header: "Sujet",
    },
    {
      accessorKey: "message",
      header: "Message",
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.created_at),
      header: "Date de création",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
  ];
  return (
    <Table
      hideAdd={true}
      hideEdit={true}
      dataUrl="contacts"
      columns={columns}
      permission="contacts"
      title="Contact Support"
    />
  );
}
