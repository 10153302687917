/* eslint-disable no-empty */
import React, { useEffect, useState } from "react";
import axiosInstance from "../../config/axios";
import Loader from "../../components/loader/loader";
import Chart from "react-apexcharts";
import DateTimeInput from "../../components/form/inputs/datetime-input/date-time-input";

export default function KpiPage() {
  const [userCount, setuserCount] = useState(0);
  const [missionCount, setMissionCount] = useState(0);
  const [statdata, setstatdata] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date(endDate.getFullYear(), endDate.getMonth(), 1));
  const stat = {
    options: {
      chart: {
        id: "basic-bar",
      },
      yaxis: {
        min: 0,
        labels: {
          style: {
            colors: "#B0B0B0",
            fontSize: "12px",
          },
        },
      },

      xaxis: {
        categories: statdata.map((elt: any) => elt._id),

        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 6,
        labels: {
          rotate: 0,
          rotateAlways: true,
          offsetX: 15,
          offsetY: 15,
          style: {
            colors: "#B0B0B0",
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
          },
        },
      },
    },
    series: [
      {
        name: "Nouvelles missions",
        data: statdata.map((elt: any) => elt.count),
      },
    ],
  };
  const [loading, setloading] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    (async () => {
      if (!userCount) setloading(true);
      try {
        if (startDate.getTime() > endDate.getTime()) return;
        const { data } = await axiosInstance.post("kpi", { startDate, endDate });
        setuserCount(data.userCount);
        setMissionCount(data.missionCount);
        setstatdata(data.missionperStat);
      } catch (e) {}
      setloading(false);
    })();
  }, [startDate, endDate]);
  if (loading) return <Loader />;
  else
    return (
      <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <div className="row gy-4">
          <div className="col" style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <div className="card bgprimary  p-3 mb-4" style={{ display: "flex", flexGrow: 1 }}>
              <span style={{ fontWeight: "bold", fontSize: 32 }}>{userCount}</span>
              <span style={{ fontSize: 14 }}>{"Nombre Total d'utilisateurs"}</span>
            </div>
            <div className="card p-3" style={{ display: "flex", flexGrow: 1 }}>
              <span style={{ fontWeight: "bold", fontSize: 32 }}>{missionCount}</span>
              <span>{"Nombre Total de Missions"}</span>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-9">
            <div className="card p-3 pb-0">
              <div className="row">
                <div className="col-sm-6 col-md" style={{marginTop:4,marginBottom:10}}>                <span style={{ fontWeight: "600", fontSize: 20 }}>{"Statistiques des missions"}</span>
                </div>
                <div className="col-sm-6  col-md">
                  <DateTimeInput
                    max={endDate}
                    onChange={(val: any) => setStartDate(val)}
                    value={startDate}
                    dateLabel="de"
                    type="date"
                  />
                </div>
                <div className="col-sm-6 col-md">
                  <DateTimeInput
                    min={startDate}
                    onChange={(val: any) => setEndDate(val)}
                    value={endDate}
                    dateLabel="jusqu'a"
                    type="date"
                  />
                </div>
              </div>
              <Chart
                options={{
                  ...stat.options,

                  colors: ["#B81A8E"],
                  grid: { strokeDashArray: 4 },
                  chart: {
                    toolbar: {
                      show: false,
                    },
                  },
                  legend: {
                    show: false,
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  fill: {
                    type: "gradient",
                    gradient: {
                      shadeIntensity: 1,
                      opacityFrom: 0.4,
                      opacityTo: 0.2,
                      stops: [15, 120, 100],
                    },
                  },
                }}
                series={stat.series}
                height={300}
                width={"100%"}
                type="area"
              />
            </div>
          </div>
        </div>
      </div>
    );
}
