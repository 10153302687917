import React from "react";
import { Route, Routes, BrowserRouter as Router, Navigate } from "react-router-dom";
import LoginPage from "../pages/login/login.page";
import { useAppContext } from "../context/app.context";
import AdminRoutes from "./conntected.routes";

export default function ConnectedRoutes() {
  const { appInfos } = useAppContext();
  const isConnected =
    appInfos.token &&
    appInfos.user.status == "valide" &&
    (appInfos?.user?.role == "admin" || appInfos?.user?.role == "employee");
  return (
    <Router>
      <Routes>
        {!isConnected && <Route path="/login" element={<LoginPage />} />}
        <Route path="*" element={isConnected ? <AdminRoutes /> : <Navigate to={"/login"} />} />
      </Routes>
    </Router>
  );
}
