/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { chooseFile, readLocalFile } from "../../utils/utils";

export default function UserImg(props: { onSelect?: Function; src?: string }) {
  const [file, setfile]: any = useState("");
  const [src, setsrc] = useState(props.src || "/images/avatar.png");
  async function onSelect() {
    const files: any = await chooseFile({ patternFile: [".jpeg", ".png", ".webp"], multiple: false });
    const file = await readLocalFile(files[0]);
    setfile(file);
    if (props.onSelect) props.onSelect(files[0]);
  }
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className="mb-3">
      <div style={{ pointerEvents: "auto", position: "relative" }}>
        <div style={{ position: "absolute", right: 0, top: 10, zIndex: 4 }} onClick={onSelect}>
          <img src="/images/edit.svg" alt="" />
        </div>
        <a target="_blank" href={src && !file ? src : ""} rel="noreferrer">
          <img
            onError={() => setsrc("/images/avatar.png")}
            style={{ pointerEvents: "auto", borderRadius: 130, width: 130, height: 130 }}
            src={file ? file : src}
            alt="user"
          />
        </a>
      </div>
    </div>
  );
}
