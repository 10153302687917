import moment from "moment";
import React from "react";
import Table from "../../components/table/table";

export default function SecteurslistPage() {
  const columns = [
    {
      accessorKey: "name",
      header: "Nom",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
  ];
  return <Table dataUrl="secteurs" columns={columns} permission="secteurs" title="Secteurs" />;
}
