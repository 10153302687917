/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
import React, { useEffect, useState } from "react";
import FormLayout from "../../../components/formlayout/formlayout";
import axiosInstance from "../../../config/axios";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../utils/utils";
import SelectInput from "../../../components/form/inputs/select-input/select-input";
import { emailReg, ibanreg, passwordReg, phonereg, sirenreg } from "../../../utils/regex";
import PlacesInput from "../../../components/form/inputs/places-input/places-inputs";
import DateTimeInput from "../../../components/form/inputs/datetime-input/date-time-input";
import UserImg from "../../../components/userimg/userimg";
import File from "../../../components/file/file";

export default function EditAddJobberPage() {
  const [loading, setIsLoading] = useState(false);
  const [notFound, setnotFound] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const isAdd = location.pathname?.endsWith("add") ? true : false;
  const [user, setuser]: any = useState({});
  const navigate = useNavigate();
  const [langues, setlangues] = useState([]);
  const [competences, setcompetences] = useState([]);
  const [secteurs, setsecteurs]: any = useState([]);
  const [metiers, setmetier] = useState([]);
  const [photo, setphoto] = useState("");
  const [status, setstatus] = useState("");
  const [kbis, setkbis] = useState("");
  const [pidentite, setpidentite] = useState("");
  const [cv, setcv] = useState("");
  const [diplome, setdiplome] = useState("");
  const [secteur, setsecteur] = useState("");
  const [rpps, setrpps] = useState(false);
  async function onSubmit(form: any) {
    form = { ...form, ...form.adresse };
    form.collectTVA= form.collectTVA=="true";
    if (form.status == "suspendu") {
      form.status = "valide";
    } else {
      form.inactiveTill = null;
    }
    const formData = new FormData();
    if (photo) formData.append("photo", photo);
    if (cv) formData.append("cv", cv);
    if (diplome) formData.append("diplome", diplome);
    if (pidentite) formData.append("pidentite", pidentite);
    if (kbis) formData.append("kbis", kbis);
    Object.keys(form).forEach((key) => {
      if (form[key] != undefined)
        if (["metiers", "competences", "location", "langues"].indexOf(key) != -1)
          formData.append(key, JSON.stringify(form[key]));
        else formData.append(key, form[key]);
    });
    try {
      if (isAdd) {
        await axiosInstance.post("users/jobbeur", formData);
        showToast("Ajout avec succés", "success");
      } else {
        await axiosInstance.patch("users/jobbeur/" + id, formData);
        showToast("Modification bien enregistré", "success");
      }
      navigate("/users/jobbers");
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Adresse e-mail prise par un autre compte");
    }
  }
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  async function getData() {
    setIsLoading(true);
    try {
      const [resCompet, resSecteur, resMetier, resLangue] = await Promise.all([
        axiosInstance.get("/competences"),
        axiosInstance.get("/secteurs"),
        axiosInstance.get("/metiers"),
        axiosInstance.get("/langues"),
      ]);
      const competData = resCompet?.data;
      setcompetences(competData);
      const secteursData = resSecteur?.data;
      setsecteurs(secteursData);
      const metiersDatas = resMetier?.data;
      setmetier(metiersDatas);
      const languesData = resLangue?.data;
      setlangues(languesData);
      if (!isAdd) {
        const { data } = await axiosInstance.get("users/jobbeur" + "/" + id);
        setrpps(data.data?.secteur?.type == "medicaux");
        setsecteur(data.data?.secteur?._id);
        const user = data.data;
        setuser(data.data);
        setstatus(
          user.status == "valide" && user.inactiveTill && new Date(user.inactiveTill).getTime() > new Date().getTime()
            ? "suspendu"
            : user.status,
        );
      }
    } catch (e: any) {
      if (e.response?.status == 404) setnotFound(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, [isAdd]);
  return (
    <FormLayout
      permission={"jobbeurs"}
      title="jobbeur"
      img="/images/users.svg"
      onSubmit={onSubmit}
      loading={loading}
      notFound={notFound}
      isAdd={isAdd}
    >
      <UserImg
        onSelect={(val: any) => setphoto(val)}
        src={process.env.REACT_APP_SERVER_URL + "/" + "photo/" + user.photo}
      />
      <div className="row" style={{ display: "flex", flexGrow: 1 }}>
        <div className={"col-sm-12 col-md-" + (status == "suspendu" ? "4" : "6")}>
          <SelectInput
            options={[
              { label: "Validé", value: "valide" },
              { label: "En attente", value: "inscrit" },
              { label: "Inactive", value: "inactive" },
              { label: "Suspendu", value: "suspendu" },
            ]}
            required="Statut invalide"
            onChange={(val: any) => setstatus(val.value)}
            value={status}
            name="status"
            label="Statut"
          />
        </div>
        {status == "suspendu" && (
          <div className={"col-sm-12 col-md-4"}>
            <DateTimeInput
              label="Jusqu'a"
              name="inactiveTill"
              type="datetime"
              required="Date invalide"
              minDateTime={new Date()}
              value={user.inactiveTill ? new Date(user.inactiveTill) : undefined}
            />
          </div>
        )}

        <div className={"col-sm-12 col-md-" + (status == "suspendu" ? "4" : "6")}>
          <TextInput
            validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
            name="email"
            label="E-mail"
            value={user.email}
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            autocomplete="new-password"
            validate={(val: any) =>
              ((val && !isAdd) || isAdd) && !passwordReg.test(val) ? "mot de passe invalide" : true
            }
            showPassword
            type="password"
            name="password"
            label="Mot de pase"
            placeHolder="*******"
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            validate={(val: any, form: any) =>
              form.password && (!val || val != form.password)
                ? "Le mot de passe n'est pas identique à celui saisi"
                : true
            }
            showPassword
            name="confirm"
            type="password"
            label="Confirmation de mot de pase"
            placeHolder="*******"
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput value={user.nom} required="Nom invalide" name="nom" label="Nom" />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput value={user.prenom} required="Prénom invalide" name="prenom" label="Prénom" />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <TextInput
            value={user.tel?.replaceAll(" ", "")?.replaceAll(":", "")?.replaceAll(".", "")}
            validate={(val: any) => (!phonereg.test(val) ? "Numéro de téléphone invalide" : true)}
            maxLength={10}
            required="Prénom invalide"
            name="tel"
            label="Numéro de téléphone"
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <TextInput
            validate={(val: any) => (!sirenreg.test(val) ? "SIRET invalide" : true)}
            maxLength={14}
            value={user.siret?.replaceAll(" ", "")?.replaceAll(":", "")?.replaceAll(".", "")}
            name="siret"
            label="SIRET"
            required="Valeur invalide"
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <PlacesInput
            value={{
              adresse: user.adresse,
              location: user.location,
              lng: user.location?.coordinates[0],
              lat: user.location?.coordinates[1],
            }}
            required="Adresse invalide"
            name="adresse"
            label="Adresse"
          />
        </div>
        <div className="col-sm-12 col-md-3 ">
          <DateTimeInput
            required="Date de naissance invalide"
            value={user.birthday}
            max={eighteenYearsAgo}
            name="birthday"
            label="Date de naissance"
          />
        </div>
        <div className="col-sm-12 col-md-3">
          <TextInput
            value={user.iban?.replaceAll(" ", "")?.replaceAll(":", "")?.replaceAll(".", "")}
            validate={(val: any) => (!ibanreg.test(val) ? "iban invalide" : true)}
            required="iban invalide"
            maxLength={27}
            name="iban"
            label="IBAN"
          />
        </div>
        <div className="col-sm-12 col-md-3 ">
          <TextInput value={user.code != "undefined" ? user.code : ""} name="code" label="Code" />
        </div>
        <div className="col-sm-12 col-md-3 ">
          <SelectInput
            options={[
              { label: "Oui", value: "true" },
              { label: "Non", value: "false" },
            ]}
            value={user?.collectTVA + ""}
            name="collectTVA"
            label="Collecte TVA"
          />
        </div>
        <div className={"col-sm-12 col-md-" + (rpps ? "6" : "12")}>
          <SelectInput
            options={secteurs.map((elt: any) => ({ value: elt._id, label: elt.name }))}
            required="Secteurs invalide "
            value={secteur || user?.secteur?._id}
            name="secteur"
            label="Secteur"
            onChange={(value: any) => {
              setrpps(secteurs.find((elt: any) => elt?._id == value?.value)?.type == "medicaux");
              setsecteur(value.value);
            }}
          />
        </div>
        {rpps && (
          <div className="col-sm-12 col-md-6 ">
            <TextInput required="RPPS invalide " value={user?.rpps} name="rpps" label="Numéro RPPS" />
          </div>
        )}
        <div className="col-sm-12 col-md-12 ">
          <SelectInput
            options={metiers
              ?.filter((elt: any) => elt.secteur._id == secteur)
              .map((elt: any) => ({ value: elt._id, label: elt.name }))}
            required="Métiers invalide"
            multiple
            value={user?.metiers?.filter((elt: any) => elt.secteur == secteur).map((elt: any) => elt?._id)}
            name="metiers"
            label="Métiers"
          />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <SelectInput
            options={competences
              ?.filter((elt: any) => elt.secteur._id == secteur)
              .map((elt: any) => ({ value: elt._id, label: elt.name }))}
            required="Compétences invalide"
            value={user?.competences?.filter((elt: any) => elt.secteur == secteur)?.map((elt: any) => elt?._id)}
            multiple
            name="competences"
            label="Compétences"
          />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <SelectInput
            options={langues.map((elt: any) => ({ value: elt._id, label: elt.name }))}
            required="Langues invalide"
            name="langues"
            value={user?.langues?.map((elt: any) => elt?._id)}
            multiple
            label="Langues"
          />
        </div>
        <div className="col-sm-12 col-md-12 mt-2 ">
          <TextInput value={user.resume} rows={5} type="textarea" name="resume" label="Resumée" />
        </div>
        <div className="col-sm-12 col-md mt-2 ">
          <File
            onSelect={(val: any) => setkbis(val)}
            type="pdf"
            label="KBIS"
            filepath={user.kbis ? "kbis/" + user.kbis : ""}
          />
        </div>
        <div className="col-sm-12 col-md mt-2 ">
          <File
            onSelect={(val: any) => setpidentite(val)}
            label="Piéce d'identité"
            type="pdf"
            filepath={user.pidentite ? "pidentite/" + user.pidentite : ""}
          />
        </div>
        <div className="col-sm-12 col-md mt-2 ">
          <File onSelect={(val: any) => setcv(val)} type="pdf" label="CV" filepath={user.cv ? "cv/" + user.cv : ""} />
        </div>
        <div className="col-sm-12 col-md mt-2 ">
          <File
            onSelect={(val: any) => setdiplome(val)}
            label="Diplôme"
            type="pdf"
            filepath={user.diplome ? "diplome/" + user.diplome : ""}
          />
        </div>
      </div>
    </FormLayout>
  );
}
