/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axiosInstance from "../../config/axios";
import { useAppContext } from "../../context/app.context";
import { showToast } from "../../utils/utils";

export default function Interceptor(props: any) {
  const [ready, setready] = useState(false);
  const { appInfos, setAppInfos } = useAppContext();
  useEffect(() => {
    const resInterceptor = axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);
    const reqInterceptor = axiosInstance.interceptors.request.use(requestInterceptor, errorInterceptor);
    setready(true);
    return () => {
      axiosInstance.interceptors.response.eject(resInterceptor);
      axiosInstance.interceptors.request.eject(reqInterceptor);
    };
  }, [appInfos.token]);
  async function requestInterceptor(request: any) {
    if (appInfos.token) request.headers.Authorization = "bearer " + appInfos.token;
    if (appInfos.token && appInfos.user.type == "Employee" && request.url != "/profil" && request.url != "profil") {
      await axiosInstance
        .get("/profil")
        .then((result: any) => {
          const data = result.data;
          setAppInfos({ user: data.data });
        })
        .catch(() => {});
    }
    return request;
  }
  async function responseInterceptor(response: any) {
    return response;
  }
  async function errorInterceptor(error: any) {
    const status = error?.response?.status;
    if (status == 401 || status == 403) {
      setAppInfos({ token: null, user: null });
    }
    if (error.code == "ERR_NETWORK") {
      showToast("Probléme de connexion");
    }
    if (error.response?.status == 422) {
      showToast(error.response?.data?.message);
    }
    if (status == 500) showToast("Une erreur est survenue");
    return Promise.reject(error);
  }
  return ready && props.children;
}
