import React from "react";
import Sidebar from "../components/sidebar/sidebar";
import { Navigate, Route, Routes } from "react-router-dom";
import KpiPage from "../pages/kpi/kpi";
import AdminslistPage from "../pages/users/admins/list.page";
import EditAddAdminPage from "../pages/users/admins/editAdd.page";
import JobberslistPage from "../pages/users/jobbers/list.page";
import EditAddJobberPage from "../pages/users/jobbers/editAdd.page";
import EntrepriseslistPage from "../pages/users/entreprises/list.page";
import EditAddEntreprisePage from "../pages/users/entreprises/editAdd.page";
import GroupslistPage from "../pages/users/groups/list.page";
import EmployeeListPage from "../pages/users/employee/list.page";
import EditAddEmployeePage from "../pages/users/employee/editAdd.page";
import MissionListPage from "../pages/missions/list.page";
import EditAddMissionPage from "../pages/missions/editAdd.page";
import FactureslistPage from "../pages/factures/list.page";
import EditAddFacturePage from "../pages/factures/editAdd.page";
import ZoneslistPage from "../pages/zones/list.page";
import EditAddZonePage from "../pages/zones/editAdd.page";
import LangueslistPage from "../pages/langues/list.page";
import EditAddLanguePage from "../pages/langues/editAdd.page";
import SecteurslistPage from "../pages/secteurs/list.page";
import EditAddSecteurPage from "../pages/secteurs/editAdd.page";
import CompetenceslistPage from "../pages/competences/list.page";
import EditAddCompetencesPage from "../pages/competences/editAdd.page";
import MetierslistPage from "../pages/metiers/list.page";
import EditAddMetierPage from "../pages/metiers/editAdd.page";

import ContactslistPage from "../pages/contacts/list.page";
import EditAddContactPage from "../pages/contacts/editAdd.page";
import EditProfilPage from "../pages/profil/edit.page";
import NotesMissionsListPage from "../pages/notes/notesmissions/list.page";
import NoteMissionPage from "../pages/notes/notesmissions/editAdd.page";
import NotesJobbeursListPage from "../pages/notes/notesjobbers/list.page";
import NoteJobberPage from "../pages/notes/notesjobbers/edit.page";
import { useAppContext } from "../context/app.context";
import EditAddGroupPage from "../pages/users/groups/editAdd.page";
import EditAddCommission from "../pages/commission/editAdd.page";
export default function ConnectedRoutes() {
  const { appInfos } = useAppContext();
  const routes: any[] = [
    {
      route: "/profil",
      page: <EditProfilPage />,
      hide: true,
    },
    {
      label: "Accueil",
      route: "/",
      icon: "/images/accueil.svg",
      page: <KpiPage />,
      noeditadd: true,
    },
    {
      label: "Utilisateurs",
      route: "/users",
      icon: "/images/users.svg",
      nested: [
        {
          label: "Administrateurs",
          route: "admins",
          page: <AdminslistPage />,
          editAddPage: <EditAddAdminPage />,
          permission: "admins",
        },
        {
          label: "Employées",
          route: "employees",
          permission: "employees",
          page: <EmployeeListPage />,
          editAddPage: <EditAddEmployeePage />,
        },
        {
          label: "Jobbeurs",
          route: "jobbers",
          permission: "jobbeurs",
          page: <JobberslistPage />,
          editAddPage: <EditAddJobberPage />,
        },
        {
          label: "Entreprises",
          route: "entreprises",
          permission: "entreprises",
          page: <EntrepriseslistPage />,
          editAddPage: <EditAddEntreprisePage />,
        },
        {
          label: "Groupes",
          route: "groups",
          permission: "groups",
          page: <GroupslistPage />,
          editAddPage: <EditAddGroupPage />,
        },
      ],
    },
    {
      label: "Missions",
      route: "/missions",
      permission: "missions",
      icon: "/images/mission.svg",
      page: <MissionListPage />,
      editAddPage: <EditAddMissionPage />,
    },
    {
      label: "Factures",
      route: "/factures",
      permission: "factures",
      icon: "/images/accueil.svg",
      page: <FactureslistPage />,
      editAddPage: <EditAddFacturePage />,
    },
    {
      label: "Zones",
      permission: "zones",
      route: "/zones",
      icon: "/images/accueil.svg",
      page: <ZoneslistPage />,
      editAddPage: <EditAddZonePage />,
    },
    
    {
      label: "Commission",
      route: "/commission",
      permission: "commission",
      icon: "/images/accueil.svg",
      page: <EditAddCommission />,
      noeditadd: true,
    },
    {
      label: "Langues",
      route: "/langues",
      permission: "langues",
      icon: "/images/langue.svg",
      page: <LangueslistPage />,
      editAddPage: <EditAddLanguePage />,
    },
    {
      label: "Secteurs",
      route: "/secteurs",
      permission: "secteurs",
      icon: "/images/accueil.svg",
      page: <SecteurslistPage />,
      editAddPage: <EditAddSecteurPage />,
    },
    {
      label: "Compétences",
      route: "/competences",
      permission: "competences",
      icon: "/images/competence.svg",
      page: <CompetenceslistPage />,
      editAddPage: <EditAddCompetencesPage />,
    },
    {
      label: "Métier",
      route: "/metiers",
      permission: "metiers",
      icon: "/images/metier.svg",
      page: <MetierslistPage />,
      editAddPage: <EditAddMetierPage />,
    },
    {
      label: "Support",
      route: "/contacts",
      permission: "contacts",
      icon: "/images/contact.svg",
      page: <ContactslistPage />,
      editAddPage: <EditAddContactPage />,
    },
    {
      label: "Notes",
      icon: "/images/note.svg",
      route: "/notes",
      nested: [
        {
          route: "missions",
          label: "Notes Missions",
          permission: "notesmissions",
          page: <NotesMissionsListPage />,
          editAddPage: <NoteMissionPage />,
        },
        {
          route: "jobbeur",
          label: "Notes Jobbeurs",
          permission: "notesjobbeur",
          page: <NotesJobbeursListPage />,
          editAddPage: <NoteJobberPage />,
        },
      ],
    },
  ];
  return (
    <div className="row" style={{ height: "100%", margin: 0, padding: 0 }}>
      <div className="col-auto p-0 m-0">
        <Sidebar routes={routes} />
      </div>
      <div className="page col-md-8">
        <Routes>
          {routes.map((route, index) => {
            const emplyee = route.permission && appInfos.user.type == "Employee";
            const perms = appInfos.user?.permissions && appInfos.user?.permissions[route.permission];
            const disable = emplyee && !perms?.find((elt: any) => elt == "read");
            return (
              <Route key={index} path={route.route}>
                {route.route && route.page && !disable ? <Route path="" element={route.page} /> : null}
                {!route.noeditadd && route.editAddPage && !disable ? (
                  <Route path="add" element={route.editAddPage} />
                ) : null}
                {!route.noeditadd && route.editAddPage && !disable ? (
                  <Route path=":id" element={route.editAddPage} />
                ) : null}
                {route.nested &&
                  route.nested.map((nested: any, ind1: any) => {
                    const emplyee = nested.permission && appInfos.user.type == "Employee";
                    const perms = appInfos.user?.permissions && appInfos.user?.permissions[nested.permission];
                    const disablenested = emplyee && !perms?.find((elt: any) => elt == "read");
                    return (
                      <Route key={index + "" + ind1} path={nested.route}>
                        {nested.route && nested.page && !disablenested ? <Route path="" element={nested.page} /> : null}
                        {!nested.noeditadd && nested.editAddPage && !disablenested ? (
                          <Route path="add" element={nested.editAddPage} />
                        ) : null}
                        {!nested.noeditadd && nested.editAddPage && !disablenested ? (
                          <Route path=":id" element={nested.editAddPage} />
                        ) : null}
                      </Route>
                    );
                  })}
              </Route>
            );
          })}
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </div>
    </div>
  );
}
