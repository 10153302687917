/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FormLayout from "../../components/formlayout/formlayout";
import axiosInstance from "../../config/axios";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { showToast } from "../../utils/utils";
import Label from "../../components/form/label/label";

export default function EditAddCommission() {
  const [loading, setIsLoading] = useState(false);
  const [notFound, setnotFound] = useState(false);
  const isAdd = false;
  const [data, setdata]: any = useState({});
  const [zones, setzones] = useState([]);
  const [prices, setprices]: any = useState([]);
  async function onSubmit(form: any) {
    try {
      form.pricesPerZone = prices;
      await axiosInstance.patch("commission", form);
      showToast("Modification bien enregistré", "success");
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Nom prise par une autre zone");
    }
  }

  async function getData() {
    setIsLoading(true);
    try {
      const zoneData = (await axiosInstance.get("/zones")).data;
      setzones(zoneData);
      if (!isAdd) {
        const { data } = await axiosInstance.get("commission");
        if (data.data.pricesPerZone) setprices(data.data.pricesPerZone);
        setdata(data.data);
      }
    } catch (e: any) {
      if (e.response?.status == 404) setnotFound(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, [isAdd]);
  console.log("lkkkkkkk");
  return (
    <FormLayout
      permission={"commission"}
      title="commission"
      img=""
      onSubmit={onSubmit}
      loading={loading}
      notFound={notFound}
      isAdd={isAdd}
    >
      <div className="row" style={{ display: "flex", flexGrow: 1 }}>
        <div className="col-sm-12 col-md-12 ">
          <TextInput
            value={data.commission}
            required="Comission invalide"
            type="number"
            name="commission"
            validate={(val: any) => {
              if (+val < 0 || +val > 100) return "pourcentage invalide";
              return true;
            }}
            label="Commission par défaut"
          />
        </div>
        {zones.length > 0 && (
          <div className="col-sm-12 col-md-12 mt-2">
            <Label label="Commission par zone" labelClass="perm " />
            <div className="row mt-2">
              {zones.map((zone: any) => (
                <div key={zone.id} className="col-sm-12 col-md-6">
                  <TextInput
                    onChange={(value: any) => {
                      let copy = [...prices];
                      const exist = prices.find((elt: any) => elt.zone == zone._id);
                      if (!exist && value) copy.push({ zone: zone._id, price: +value });
                      else {
                        if (!value) copy = copy.filter((elt) => elt.zone != zone._id);
                        else exist.price = value;
                      }
                      setprices(copy);
                    }}
                    validate={(val: any) => {
                      if ((val && +val < 0) || +val > 100) return "pourcentage invalide";
                      return true;
                    }}
                    name={zone.name}
                    placeHolder="Commission par zone"
                    value={prices.find((elt: any) => elt.zone == zone._id)?.price}
                    label={zone.name}
                    type="number"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </FormLayout>
  );
}
