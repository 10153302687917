/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FormLayout from "../../components/formlayout/formlayout";
import axiosInstance from "../../config/axios";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import SelectInput from "../../components/form/inputs/select-input/select-input";
import Label from "../../components/form/label/label";
import File from "../../components/file/file";

export default function EditAddCompetencesPage() {
  const [loading, setIsLoading] = useState(false);
  const [notFound, setnotFound] = useState(false);
  const [photo, setphoto] = useState("");
  const { id } = useParams();
  const location = useLocation();
  const isAdd = location.pathname?.endsWith("add") ? true : false;
  const [data, setdata]: any = useState({});
  const navigate = useNavigate();
  const [zones, setzones] = useState([]);
  const [secteurs, setsecteurs] = useState([]);
  const [prices, setprices]: any = useState([]);
  async function onSubmit(form: any) {
    try {
      const formData = new FormData();
      if (photo) {
        formData.append("photo", photo);
      }
      formData.append("name", form.name);
      if (form.minPrice) formData.append("minPrice", form.minPrice);
      formData.append("secteur", form.secteur);
      formData.append("pricesPerZone", JSON.stringify(prices));
      if (isAdd) {
        await axiosInstance.post("metiers", formData);
        showToast("Ajout avec succés", "success");
      } else {
        await axiosInstance.patch("metiers/" + id, formData);
        showToast("Modification bien enregistré", "success");
      }
      navigate(-1);
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Nom prise par une autre zone");
    }
  }

  async function getData() {
    setIsLoading(true);
    try {
      const zoneData = (await axiosInstance.get("/zones")).data;
      setzones(zoneData);
      const secteurData = (await axiosInstance.get("/secteurs")).data;
      setsecteurs(secteurData);
      if (!isAdd) {
        const { data } = await axiosInstance.get("metiers" + "/" + id);
        if (data.data.pricesPerZone) setprices(data.data.pricesPerZone);
        setdata(data.data);
      }
    } catch (e: any) {
      if (e.response?.status == 404) setnotFound(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, [isAdd]);
  return (
    <FormLayout
      permission={"metiers"}
      title="métier"
      img=""
      onSubmit={onSubmit}
      loading={loading}
      notFound={notFound}
      isAdd={isAdd}
    >
      <div className="row" style={{ display: "flex", flexGrow: 1 }}>
        <div className="col-sm-12 col-md-12 ">
          <TextInput value={data.name} required="Nom invalide" name="name" label="Nom" />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <SelectInput
            options={secteurs.map((elt: any) => ({ label: elt.name, value: elt._id }))}
            value={data.secteur}
            name="secteur"
            label="Secteur"
          />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <TextInput value={data.minPrice} type="number" name="minPrice" label="Rémunération minimum par heure" />
        </div>
        <div className="col-sm-12 col-md-12 mt-2 ">
          <File
            minHeight={180}
            maxWidth={180}
            onSelect={(val: any) => setphoto(val)}
            label="Image"
            filepath={data.image ? "photo/" + data.image : ""}
          />
        </div>
        {zones.length ? (
          <div className="col-sm-12 col-md-12 mt-2">
            <Label label="Rémuneration minimum par zone" labelClass="perm " />
            <div className="row mt-2">
              {zones.map((zone: any) => (
                <div key={zone.id} className="col-sm-12 col-md-6">
                  <TextInput
                    onChange={(value: any) => {
                      let copy = [...prices];
                      const exist = prices.find((elt: any) => elt.zone == zone._id);
                      if (!exist && value) copy.push({ zone: zone._id, price: +value });
                      else {
                        if (!value) copy = copy.filter((elt) => elt.zone != zone._id);
                        else exist.price = value;
                      }
                      setprices(copy);
                    }}
                    placeHolder="Rémunération minimum par heure"
                    value={prices.find((elt: any) => elt.zone == zone._id)?.price}
                    label={zone.name}
                    type="number"
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </FormLayout>
  );
}
