import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "../../../components/table/table";

export default function GroupslistPage() {

  const columns = [
    {
      accessorKey: "name",
      header: "Nom",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.created_at),
      header: "Date de création",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
  ];

  return <Table  dataUrl="groups" columns={columns} permission="groups" title="Groupes" />;
}
