import React from "react";
import Table from "../../components/table/table";

export default function LangueslistPage() {
  const columns = [
    {
      accessorKey: "name",
      header: "Nom",
    },
  ];
  return <Table dataUrl="langues" columns={columns} permission="langues" title="Langues" />;
}
