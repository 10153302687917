import React, { useEffect, useState } from "react";
import FormLayout from "../../components/formlayout/formlayout";
import TextInput from "../../components/form/inputs/text-input/text-input";
import axiosInstance from "../../config/axios";
import { showToast } from "../../utils/utils";
import { emailReg, passwordReg } from "../../utils/regex";
import { useAppContext } from "../../context/app.context";

export default function EditProfilPage() {
  const [loading, setIsLoading] = useState(false);
  const isAdd = false;
  const [data, setdata]: any = useState({});
  const { appInfos, setAppInfos } = useAppContext();
  async function onSubmit(form: any) {
    try {
      await axiosInstance.post("profil", form);
      setAppInfos({
        user: { ...appInfos.user, ...form },
      });
      showToast("Profil bien modifié", "success");
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("adresse e-mail prise par un autre compte");
    }
  }

  async function getData() {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get("/profil");
      setdata(data.data);
    } catch (e: any) {}
    setIsLoading(false);
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <FormLayout title="profil" img="/images/users.svg" onSubmit={onSubmit} loading={loading} isAdd={isAdd}>
      <div className="row" style={{ display: "flex", flexGrow: 1 }}>
        <div className="col-sm-12 col-md-6 ">
          <TextInput value={data.nom} required="Nom invalide" name="nom" label="Nom" />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput value={data.prenom} required="Prénom invalide" name="prenom" label="Prénom" />
        </div>

        <div className="col-sm-12 col-md-6 ">
          <TextInput
            validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
            value={data.email}
            name="email"
            label="E-mail"
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            validate={(val: any) => (val && !passwordReg.test(val) ? "mot de passe invalide" : true)}
            type="password"
            autocomplete="new-password"
            label="Mot de pase"
            showPassword
            name="password"
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            validate={(val: any, form: any) =>
              form.password && (!val || val != form.password)
                ? "Le mot de passe n'est pas identique à celui saisi"
                : true
            }
            name="passconfirm"
            showPassword
            type="password"
            label="Confirmation de mot de pase"
          />
        </div>
      </div>
    </FormLayout>
  );
}
