/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FormLayout from "../../components/formlayout/formlayout";
import axiosInstance from "../../config/axios";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import SelectInput from "../../components/form/inputs/select-input/select-input";
import Label from "../../components/form/label/label";
import DateTimeInput from "../../components/form/inputs/datetime-input/date-time-input";

export default function EditAddFacturePage() {
  const [loading, setIsLoading] = useState(false);
  const [notFound, setnotFound] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const isAdd = location.pathname?.endsWith("add") ? true : false;
  const [data, setdata]: any = useState({});
  const [jobbeur, setjobbeur] = useState([]);
  const [entreprises, setentreprises] = useState([]);
  const [type, settype] = useState("commission");
  const navigate = useNavigate();
  async function onSubmit(form: any) {
    try {
      if (isNaN(form.tvapercent) || form.tvapercent > 100 || form.tvapercent < 0)
        return showToast("Pourcentage tva invalide");
      if (!form.idJobbeur) form.idJobbeur = undefined;
      if (isAdd) {
        await axiosInstance.post("factures", form);
        showToast("Ajout avec succés", "success");
      } else {
        await axiosInstance.patch("factures/" + id, form);
        showToast("Modification bien enregistré", "success");
      }
      navigate(-1);
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Nom prise par une autre zone");
    }
  }

  async function getData() {
    setIsLoading(true);
    try {
      const jobbeurData = (await axiosInstance.get("/users/jobbeur")).data;
      setjobbeur(jobbeurData.data);
      const entrepriseData = (await axiosInstance.get("/users/entreprise")).data;
      setentreprises(entrepriseData.data);
      if (!isAdd) {
        const { data } = await axiosInstance.get("factures" + "/" + id);
        settype(data.data.type);
        setdata(data.data);
      }
    } catch (e: any) {
      if (e.response?.status == 404) setnotFound(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, [isAdd]);
  return (
    <FormLayout
      permission={"factures"}
      title="facture"
      img=""
      onSubmit={onSubmit}
      loading={loading}
      notFound={notFound}
      isAdd={isAdd}
    >
      <div className="row" style={{ display: "flex", flexGrow: 1 }}>
        <div className="col-sm-12 col-md-12 ">
          <SelectInput
            options={[
              { label: "commission", value: "commission" },
              { label: "rémunération", value: "rémunération" },
              { label: "jobbeur", value: "jobbeur" },
            ]}
            onChange={(val: any) => settype(val.value)}
            disabled={!isAdd && !data.manually}
            value={data?.type || type}
            name="type"
            label="Type facture"
          />
        </div>
        {!isAdd && (
          <div className="col-sm-12 col-md-12 ">
            <TextInput value={data.numFacture} disabled={true} label="Numéro de facture" />
          </div>
        )}
        <div className="col-sm-12 col-md-6 ">
          <DateTimeInput
            value={data.facturationDate || new Date()}
            required="Date de facturation invalide"
            name="facturationDate"
            label="Date de facturation"
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            value={
              data.description || type == "commission"
                ? "Recherche et mise en relation avec un prestataire"
                : "Prestation de service"
            }
            required="description invalide"
            name="description"
            label="Description"
          />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <TextInput
            type="number"
            value={data.priceHT}
            required="Prix hors tax invalide"
            name="priceHT"
            label="Prix hors tax"
          />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <TextInput
            type="text"
            value={data.tvapercent != undefined ? data.tvapercent + "" : type == "commission" ? "20" : "0"}
            required="Pourcentage tva invalide"
            name="tvapercent"
            label="Pourcentage tva"
            min={0}
            max={100}
          />
        </div>
        {type != "commission" && (
          <div className="col-sm-12 col-md-12 ">
            <SelectInput
              options={jobbeur?.map((elt: any) => ({ label: elt.prenom + " " + elt.nom, value: elt._id }))}
              value={data.idJobbeur?._id}
              disabled={!isAdd && !data.manually}
              required={isAdd ? "Sélectionner un jobbeur" : false}
              name="idJobbeur"
              label="Jobbeur"
            />
          </div>
        )}
        <div className="col-sm-12 col-md-12 ">
          <SelectInput
            disabled={!isAdd && !data.manually}
            options={entreprises?.map((elt: any) => ({ label: elt.NomEntreprise, value: elt._id }))}
            value={data.idEntreprise?._id}
            required={isAdd ? "Sélectionner une entreprise" : false}
            name="idEntreprise"
            label="Entreprise"
          />
        </div>
      </div>
    </FormLayout>
  );
}
