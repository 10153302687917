import moment from "moment";
import React from "react";
import Table from "../../components/table/table";
import Button from "../../components/form/button/button";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function MissionslistPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jobbeur = searchParams.get("jobbeur");
  const entreprise = searchParams.get("entreprise");
  const columns = [
    {
      accessorKey: "status",
      header: "Statut",
      filterVariant: "multi-select",
      filterSelectOptions: [
        { label: "Terminée", value: "terminée" },
        { label: "En attente", value: "enattente" },
        { label: "En cours", value: "encours" },
        { label: "A venir", value: "avenir" },
        { label: "Annulée", value: "annulee" },
        { label: "En attente de paiement", value: "payment" },
      ],
      size: 30,
      Cell: ({ cell }: any) => {
        const tag = statusMission(cell.row.original.status);
        return <span className={"badge " + tag.class}>{tag.title}</span>;
      },
    },
    {
      accessorKey: "idUser.NomEntreprise",
      header: "Nom de l'entreprise",
    },
    {
      accessorKey: "idJobbeur.nom",
      header: "Jobbeur",
    },
    {
      accessorFn: (originalRow: any) => +originalRow.remuneration,
      accessorKey: "remuneration",
      header: "Rémunération",
      filterVariant: "range",
    },
    {
      accessorKey: "zone.name",
      header: "Zone",
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.dateDebut),
      header: "Date Début",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => new Date(+cell.getValue()).toLocaleString("fr-FR", { timeZone: "Europe/Paris" }),
      type: "date",
      size: 40,
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.dateFin),
      header: "Date Fin",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => new Date(+cell.getValue()).toLocaleString("fr-FR", { timeZone: "Europe/Paris" }),
      type: "date",
      size: 40,
    },
    {
      accessorFn: (originalRow: any) => new Date(originalRow.created_at),
      header: "Date de création",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => new Date(+cell.getValue()).toLocaleString("fr-FR", { timeZone: "Europe/Paris" }),
      type: "date",
    },
    {
      accessorKey: "facture",
      header: "Factures",
      size: 30,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: any) => {
        const id = cell.row.original._id;
        return (
          <Button
            onClick={() => {
              navigate("/factures?mission=" + id);
            }}
            disabled={cell.row.original.status != "terminée"}
            className="btn btn-secondary btnsmall"
            title="Voir"
          />
        );
      },
    },
    {
      accessorKey: "notemission",
      header: "Note mission",
      enableColumnFilter: false,
      enableSorting: false,
      size: 30,
      Cell: ({ cell }: any) => {
        const id = cell.row.original._id;
        return (
          <Button
            onClick={() => {
              navigate("/notes/missions?mission=" + id);
            }}
            disabled={cell.row.original.status != "terminée"}
            className="btn btn-secondary btnsmall"
            title="Voir"
          />
        );
      },
    },
    {
      accessorKey: "notejobbeur",
      header: "Note Jobbeur",
      size: 30,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: any) => {
        const id = cell.row.original._id;
        return (
          <Button
            onClick={() => {
              navigate("/notes/jobbeur?mission=" + id);
            }}
            disabled={cell.row.original.status != "terminée"}
            className="btn btn-secondary btnsmall"
            title="Voir"
          />
        );
      },
    },
  ];

  return (
    <Table
      mapData={getStatus}
      dataUrl={"missions" + (jobbeur ? "?jobbeur=" + jobbeur : "") + (entreprise ? "?entreprise=" + entreprise : "")}
      columns={columns}
      permission="missions"
      title="Missions"
    />
  );
}
export function statusMission(status: any) {
  if (status == "encours") return { title: "En cours", class: "badge-blue" };
  if (status == "enattente") return { title: "En attente", class: "badge-warning" };
  if (status == "payment") return { title: "En attente de paiement", class: "badge-warning" };
  if (status == "avenir") return { title: "A venir", class: "badge-violet" };
  if (status == "annulee") return { title: "Annulée", class: "badge-danger" };
  if (status == "terminée") return { title: "Terminée", class: "badge-success" };
  return { title: "", class: "" };
}
export function getStatus(elt: any) {
  let status = elt.status;
  
  if (status == "payment" && moment(elt.dateDebut).isAfter(new Date())) {
    status = "payment";
  } else if (status == "payment" && new Date(elt.dateDebut).getTime() < new Date().getTime()) {
    status = "annulee";
  } else if (status == "annulee") {
    status = "annulee";
  } else if ((!elt.idJobbeur || elt.idJobbeur == null) && moment(elt.dateFin).isBefore(new Date())) {
    status = "annulee";
  } else if (elt.status === "enattente" && moment(elt.dateDebut).isAfter(new Date())) {
    status = "enattente";
  } else if (
    elt?.idJobbeur?._id &&
    moment(elt.dateDebut).isBefore(new Date()) &&
    moment(elt.dateFin).isAfter(new Date())
  ) {
    status = "encours";
  } else if (
    !elt?.idJobbeur?._id &&
    moment(elt.dateDebut).isBefore(new Date()) &&
    moment(elt.dateFin).isAfter(new Date())
  ) {
    status = "annulee";
  } else if (moment(elt.dateDebut).isAfter(new Date())) {
    status = "avenir";
  } else if (moment(elt.dateFin).isBefore(new Date())) status = "terminée";
  elt.status = status;
  if (elt.idJobbeur) elt.idJobbeur.nom = (elt?.idJobbeur?.prenom || "") + " " + (elt?.idJobbeur?.nom || "");
  return elt;
}
