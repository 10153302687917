import React from "react";
import "./404.page.css";
export default function Page404() {
  return (
    <div className="page404">
      <div className="container">
        <div className="error">
          <h1>404</h1>
          <p>Page non trouvée.</p>
          <a href="/">{"Retour à la page d'accueil"}</a>
        </div>
      </div>
    </div>
  );
}
