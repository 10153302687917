/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FormLayout from "../../../components/formlayout/formlayout";
import axiosInstance from "../../../config/axios";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EntrepriseTypes, showToast } from "../../../utils/utils";
import SelectInput from "../../../components/form/inputs/select-input/select-input";
import { emailReg, passwordReg, phonereg, sirenreg } from "../../../utils/regex";
import PlacesInput from "../../../components/form/inputs/places-input/places-inputs";
import Switch from "@mui/material/Switch";
import Label from "../../../components/form/label/label";
import File from "../../../components/file/file";
import { useAppContext } from "../../../context/app.context";

export default function EditAddEntreprisePage() {
  const [loading, setIsLoading] = useState(false);
  const [notFound, setnotFound] = useState(false);
  const [secteurs, setsecteurs] = useState([]);
  const {appInfos}=useAppContext();
  const { id } = useParams();
  const location = useLocation();
  const emplyee = appInfos.user.type == "Employee";
  const perms = appInfos.user?.permissions && appInfos.user?.permissions["entreprises"];
  const disableSepa = emplyee && !perms?.find((elt: any) => elt == "enableSepaPayment");
  const disableSchedule = emplyee && !perms?.find((elt: any) => elt == "enableScheduledPayment");
  const isAdd = location.pathname?.endsWith("add") ? true : false;
  const [user, setuser]: any = useState({});
  const navigate = useNavigate();
  const [enableSepaPayment, setenablesepa] = useState(false);
  const [enableScheduledPayment, setenableScheduledPayment] = useState(false);
  const [groups, setgroups] = useState([]);
  const [kbis, setkbis] = useState("");
  async function onSubmit(form: any) {
    try {
      form = { ...form, ...form.adresse };
      if (!form.groupEntreprise) delete form.groupEntreprise;
      form.enableSepaPayment = enableSepaPayment;
      form.enableScheduledPayment = enableScheduledPayment;
      const formData = new FormData();
      if (kbis) formData.append("kbis", kbis);
      Object.keys(form).forEach((key) => {
        if (form[key] != undefined)
          if (["metiers", "competences", "location", "langues"].indexOf(key) != -1)
            formData.append(key, JSON.stringify(form[key]));
          else formData.append(key, form[key]);
      });
      if (isAdd) {
        await axiosInstance.post("users/entreprise", formData);
        showToast("Ajout avec succés", "success");
      } else {
        await axiosInstance.patch("users/entreprise/" + id, formData);
        showToast("Modification bien enregistré", "success");
      }
      navigate(-1);
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Adresse e-mail prise par un autre compte");
    }
  }

  async function getData() {
    setIsLoading(true);
    try {
      const grps = (await axiosInstance.get("groups")).data.map((elt: any) => ({ value: elt._id, label: elt.name }));
      setgroups(grps);
      const secteursData = (await axiosInstance.get("secteurs")).data;
      setsecteurs(secteursData);
      if (!isAdd) {
        const { data } = await axiosInstance.get("users/entreprise" + "/" + id);

        setuser(data.data);
        setenablesepa(data.data?.enableSepaPayment);
        setenableScheduledPayment(data.data?.enableScheduledPayment);
      }
    } catch (e: any) {
      if (e.response?.status == 404) setnotFound(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, [isAdd]);

  return (
    <FormLayout
      permission={"entreprises"}
      title="entreprises"
      img="/images/users.svg"
      onSubmit={onSubmit}
      loading={loading}
      notFound={notFound}
      isAdd={isAdd}
    >
      <div className="row" style={{ display: "flex", flexGrow: 1 }}>
        <div className="col-sm-12 col-md-6 ">
          <SelectInput
            options={[
              { label: "Validé", value: "valide" },
              { label: "En attente", value: "inscrit" },
              { label: "Inactive", value: "inactive" },
            ]}
            required="Statut invalide"
            value={user.status}
            name="status"
            label="Statut"
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
            name="email"
            label="E-mail"
            value={user.email}
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            autocomplete="new-password"
            validate={(val: any) =>
              ((val && !isAdd) || isAdd) && !passwordReg.test(val) ? "mot de passe invalide" : true
            }
            showPassword
            type="password"
            name="password"
            label="Mot de pase"
            placeHolder="*******"
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            validate={(val: any, form: any) =>
              form.password && (!val || val != form.password)
                ? "Le mot de passe n'est pas identique à celui saisi"
                : true
            }
            showPassword
            name="confirm"
            type="password"
            label="Confirmation de mot de pase"
            placeHolder="*******"
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <TextInput
            required="Nom invalide"
            label="Nom de l'entreprise"
            name="NomEntreprise"
            value={user.NomEntreprise}
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <SelectInput
            options={EntrepriseTypes}
            required="Statut invalide"
            name="statutEntreprise"
            value={user.statutEntreprise}
            label="Statut de l'entreprise"
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <SelectInput
            options={groups}
            name="groupEntreprise"
            value={user.groupEntreprise?._id}
            label="Groupe de l'entreprise"
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <TextInput value={user.nom} required="Nom invalide" name="nom" label="Nom gérant" />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <TextInput value={user.prenom} required="Prénom invalide" name="prenom" label="Prénom gérant" />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <TextInput
            value={user.tel?.replaceAll(" ", "")?.replaceAll(":", "")?.replaceAll(".", "")}
            validate={(val: any) => (!phonereg.test(val) ? "Numéro de téléphone invalide" : true)}
            maxLength={10}
            required="Numéro de téléphone invalide"
            name="tel"
            label="Numéro de téléphone"
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <TextInput
            maxLength={14}
            validate={(val: any) => (!sirenreg.test(val) ? "SIRET invalide" : true)}
            value={user.siret?.replaceAll(" ", "")?.replaceAll(":", "")?.replaceAll(".", "")}
            name="siret"
            label="SIRET"
            required="SIRET invalide"
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <PlacesInput
            value={{
              adresse: user.adresse,
              location: user.location,
              lng: user.location?.coordinates[0],
              lat: user.location?.coordinates[1],
            }}
            required="Adresse invalide"
            name="adresse"
            label="Adresse"
          />
        </div>
        <div className="col-sm-12 col-md-4 ">
          <TextInput value={user.code && user.code != "undefined" ? user.code : ""} name="code" label="Code" />
        </div>
        <div className={"col-sm-12 col-md-4"}>
          <SelectInput
            options={secteurs.map((elt: any) => ({ value: elt._id, label: elt.name }))}
            required="Secteurs invalide "
            value={user?.secteur?._id}
            name="secteur"
            label="Secteur"
          />
        </div>
        <div className="col-sm-12 col-md-4 container ">
          <Label label="Paiement SEPA" />
          <div>
            <Switch
              onChange={() =>
                setenablesepa((old: any) => {
                  return !old;
                })
              }
              checked={enableSepaPayment}
              disabled={disableSepa}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 container ">
          <Label label="Prélévement fin de moi" />
          <div>
            <Switch
              onChange={() =>
                setenableScheduledPayment((old: any) => {
                  return !old;
                })
              }
              checked={enableScheduledPayment}
              disabled={disableSchedule}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-4  ">
          <File
            onSelect={(val: any) => setkbis(val)}
            type="pdf"
            label="KBIS"
            filepath={user.kbis ? "kbis/" + user.kbis : ""}
          />
        </div>
      </div>
    </FormLayout>
  );
}
