/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useCallback, useEffect, useState } from "react";

const iniInfos: { token?: string | null; user?: any; language?: string; theme?: string } = {
  token: null,
  user: null,
  language: "fr",
  theme: "light",
};

const AppContext = createContext(iniInfos);

const AppContextUpdater = createContext((state: typeof iniInfos) => {
  return state as any;
});

export const useAppUpdate = () => {
  return React.useContext(AppContextUpdater);
};
export const useAppContext = () => {
  return { appInfos: React.useContext(AppContext), setAppInfos: useAppUpdate() };
};

export default function AppContextProvider(props: any) {
  const [appInfos, updateAppInfos] = useState(iniInfos);
  const [ready, setready] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        let savedInfos: any = localStorage.getItem("appInfos");
        if (savedInfos) {
          savedInfos = JSON.parse(savedInfos);
          updateAppInfos((old) => {
            return { ...old, ...savedInfos };
          });
          //strings.setLanguage(savedInfos.language);
        } else {
          //strings.setLanguage(getDeviceLanguage());
        }
        setready(true);
      } catch (e) {
        /* empty */
      }
    })();
  }, []);
  const setAppInfos = useCallback((state: typeof iniInfos) => {
    updateAppInfos((old) => {
      const newState = { ...old, ...state };
      //strings.setLanguage(newState.language);
      localStorage.setItem("appInfos", JSON.stringify(newState));
      return newState;
    });
  }, []);
  return (
    <AppContext.Provider value={appInfos}>
      <AppContextUpdater.Provider value={setAppInfos}>{ready && props.children}</AppContextUpdater.Provider>
    </AppContext.Provider>
  );
}
