import React, { useEffect, useState } from "react";
import FormLayout from "../../components/formlayout/formlayout";
import axiosInstance from "../../config/axios";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import Label from "../../components/form/label/label";
import Map from "../../components/map/map";

export default function EditAddGroupPage() {
  const [loading, setIsLoading] = useState(false);
  const [notFound, setnotFound] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const isAdd = location.pathname?.endsWith("add") ? true : false;
  const [data, setdata]: any = useState({});
  const navigate = useNavigate();
  const [file, setfile] = useState("");
  const [submit, setsubmit] = useState(false);
  async function onSubmit(form: any) {
    try {
      setsubmit(true);
      if (!file && !data.geo) return;
      const formData = new FormData();
      if (file) formData.append("kmlfile", file);
      formData.append("name", form.name);
      formData.append("description", form.description);
      formData.append("commission", form.commission);
      if (isAdd) {
        await axiosInstance.post("zones", formData);
        showToast("Ajout avec succés", "success");
      } else {
        await axiosInstance.patch("zones/" + id, formData);
        showToast("Modification bien enregistré", "success");
      }
      navigate(-1);
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Nom prise par une autre zone");
    }
  }

  async function getData() {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get("zones" + "/" + id);
      setdata(data.data);
    } catch (e: any) {
      if (e.response?.status == 404) setnotFound(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (!isAdd) getData();
  }, [isAdd]);
  return (
    <FormLayout
      permission={"zones"}
      title="zone"
      img=""
      onSubmit={onSubmit}
      loading={loading}
      notFound={notFound}
      isAdd={isAdd}
    >
      <div className="row" style={{ display: "flex", flexGrow: 1 }}>
        <div className="col-sm-12 col-md-12 ">
          <TextInput value={data.name} required="Nom invalide" name="name" label="Nom" />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <TextInput value={data.description} name="description" label="Description" />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <Label label="Fichier KML" />
          <input onChange={(e: any) => setfile(e.target.files[0])} accept=".kml" className="form-control" type="file" />
        </div>
        {submit && !file && !data.geo && (
          <div className={`form-error-msg`}>
            <img
              style={{ marginInlineEnd: 5, marginTop: -3, marginRight: 5, width: 14, height: 14 }}
              src="/images/error.svg"
              alt=""
            />
            {"KML File est obligatoire"}
          </div>
        )}
        {data.geo && <Map coordinates={data?.geo?.coordinates[0].map((elt: any) => ({ lng: elt[0], lat: elt[1] }))} />}
      </div>
    </FormLayout>
  );
}
