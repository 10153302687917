/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FormLayout from "../../components/formlayout/formlayout";
import axiosInstance from "../../config/axios";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../utils/utils";
import Label from "../../components/form/label/label";
import SelectInput from "../../components/form/inputs/select-input/select-input";

export default function EditAddSecteurPage() {
  const [loading, setIsLoading] = useState(false);
  const [notFound, setnotFound] = useState(false);
  const { id } = useParams();
  const [file, setfile] = useState("");
  const [submit, setsubmit] = useState(false);
  const location = useLocation();
  const isAdd = location.pathname?.endsWith("add") ? true : false;
  const [data, setdata]: any = useState({});
  const navigate = useNavigate();
  const [videotype, setvideotype] = useState("FILE");
  function getEmbedURL(url: any) {
    let videoID;
    if (url.includes("youtube.com/embed/")) {
      videoID = url.split("youtube.com/embed/")[1].split("?")[0];
    } else {
      const params = new URLSearchParams(new URL(url).search);
      videoID = params.get("v");
    }
    if (!videoID) {
      throw new Error("Invalid YouTube URL");
    }
    return `https://www.youtube.com/embed/${videoID}`;
  }
  async function onSubmit(form: any) {
    setsubmit(true);
    if (videotype == "FILE" && !file && !data.video) return;
    const formData = new FormData();
    if (videotype == "URL") formData.append("video", getEmbedURL(form.video));
    if (file) formData.append("video", file);
    formData.append("name", form.name);
    if (form.description != undefined) formData.append("description", form.description);
    try {
      if (isAdd) {
        await axiosInstance.post("secteurs", formData);
        showToast("Ajout avec succés", "success");
      } else {
        await axiosInstance.patch("secteurs/" + id, formData);
        showToast("Modification bien enregistré", "success");
      }
      navigate(-1);
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Nom prise par une autre zone");
    }
  }

  async function getData() {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get("secteurs" + "/" + id);
      setdata(data.data);
      if (data?.data?.video?.toLowerCase()?.indexOf("http") == -1) {
        setvideotype("FILE");
      }
    } catch (e: any) {
      if (e.response?.status == 404) setnotFound(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (!isAdd) getData();
  }, [isAdd]);
  const isURl = data.video?.toLowerCase()?.indexOf("http") != -1;
  const urlPattern =
    /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/[\w\-\.~!$&'()*+,;=:@%]*)*(\?[;&a-zA-Z0-9_.,~+&%=]*)?(#\w*)?$/i;
  return (
    <FormLayout
      permission={"secteurs"}
      title="secteur"
      img=""
      onSubmit={onSubmit}
      loading={loading}
      notFound={notFound}
      isAdd={isAdd}
    >
      <div className="row" style={{ display: "flex", flexGrow: 1 }}>
        <div className="col-sm-12 col-md-12 ">
          <TextInput value={data.name} required="Nom invalide" name="name" label="Nom" />
        </div>
        <div className="col-sm-12 col-md-12 ">
          <TextInput value={data.description} name="description" label="Description" />
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 row ">
            <Label label="Vidéo de présentation" />
            <div className="col-sm-12 col-md-3 row ">
              <SelectInput
                value={videotype}
                onChange={(val: any) => setvideotype(val.value)}
                disabled
                options={[
                  { value: "URL", label: "URL" },
                  { value: "FILE", label: "FICHIER" },
                ]}
              />
            </div>
            <div className="col-sm-12 col-md  ">
              {videotype == "FILE" ? (
                <input
                  onChange={(e: any) => setfile(e.target.files[0])}
                  accept="video/mp4,video/x-m4v,video/*"
                  className="form-control"
                  type="file"
                />
              ) : (
                <TextInput
                  validate={(val: any) => (!urlPattern.test(val) ? "URL invalide" : true)}
                  name="video"
                  placeHolder="url vidéo"
                  value={data.video}
                />
              )}
            </div>
          </div>
        </div>

        {submit && !file && !data.video && videotype == "FILE" && (
          <div className={`form-error-msg`}>
            <img
              style={{ marginInlineEnd: 5, marginTop: -3, marginRight: 5, width: 14, height: 14 }}
              src="/images/error.svg"
              alt=""
            />
            {"Vidéo de présentation est obligatoire"}
          </div>
        )}
        {data.video && (
          <iframe
            style={{ marginTop: 20 }}
            title="Video"
            width="100%"
            height="315"
            src={isURl ? data.video : process.env.REACT_APP_SERVER_URL + "/" + data.video}
          ></iframe>
        )}
      </div>
    </FormLayout>
  );
}
