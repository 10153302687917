/* eslint-disable no-empty */
import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "../../../components/table/table";
import Switch from "@mui/material/Switch";
import { EntrepriseTypes, statusUser } from "../../../utils/utils";
import axiosInstance from "../../../config/axios";
import { useAppContext } from "../../../context/app.context";
import Button from "../../../components/form/button/button";
import { useNavigate } from "react-router-dom";

export default function EntrepriseslistPage() {
  const [groups, setgroups] = useState([]);
  const [secteurs, setsecteurs] = useState([]);
  const [loading, setloading] = useState(false);
  const { appInfos } = useAppContext();
  const navigate = useNavigate();
  const emplyee = appInfos.user.type == "Employee";
  const perms = appInfos.user?.permissions && appInfos.user?.permissions["entreprises"];
  const disableSepa = emplyee && !perms?.find((elt: any) => elt == "enableSepaPayment");
  const csvRows = [
    { key: "status", label: "Statut" },
    { key: "email", label: "E-mail" },
    { key: "NomEntreprise", label: "Nom" },
    { key: "prenom", label: "Prénom gérant" },
    { key: "nom", label: "Nom gérant" },
    { key: "statutEntreprise", label: "type" },
    { key: "groupEntreprise", label: "Groupe" },
    { key: "tel", label: "Téléphone" },
    { key: "siret", label: "SIRET" },
    { key: "codePostal", label: "Code postale" },
    { key: "seuleVille", label: "Ville" },
    { key: "adresse", label: "Adresse" },
    { key: "created_at", label: "Date d'inscription" },
  ];
  const columns = [
    {
      accessorKey: "status",
      header: "Statut",
      size: 30,
      filterVariant: "multi-select",
      filterSelectOptions: [
        { label: "Validé", value: "valid" },
        { label: "En attente", value: "inscrit" },
        { label: "Inactive", value: "inactive" },
      ],
      Cell: ({ cell }: any) => {
        const tag = statusUser(cell.row.original.status);
        return <span className={"badge " + tag.class}>{tag.title}</span>;
      },
    },
    // {
    //   accessorFn: (originalRow: any) => originalRow.enableSepaPayment + "",
    //   header: "SEPA",
    //   nocsv: true,
    //   filterVariant: "multi-select",
    //   filterSelectOptions: [
    //     { label: "Activée", value: true },
    //     { label: "Desactivée", value: false },
    //   ],
    //   size: 20,
    //   Cell: ({ cell }: any) => {
    //     const [checked, setchecked] = useState(cell.row.original.enableSepaPayment);
    //     const [loading, setloading] = useState(false);
    //     return (
    //       <Switch
    //         onChange={async () => {
    //           setchecked((old: any) => {
    //             cell.row.original.enableSepaPayment = !old;
    //             return !old;
    //           });
    //           setloading(true);
    //           try {
    //             const id = cell.row.original._id;
    //             await axiosInstance.patch("users/entreprise/" + id, { enableSepaPayment: !checked });
    //           } catch (e) {}
    //           setloading(false);
    //         }}
    //         checked={checked}
    //         disabled={loading || disableSepa}
    //       />
    //     );
    //   },
    // },
    {
      accessorKey: "email",
      header: "E-mail",
      size: 30,
    },
    {
      accessorKey: "NomEntreprise",
      header: "Nom",
    },
    {
      accessorKey: "statutEntreprise",
      header: "Type",
      size: 30,
      filterVariant: "multi-select",
      filterSelectOptions: EntrepriseTypes,
    },
    {
      accessorKey: "secteur",
      accessorFn: (originalRow: any) => {
        return originalRow?.secteur?.name  || ""
      },
      header: "Secteur",
      filterVariant: "multi-select",
      filterSelectOptions: secteurs,
      size: 250,
    },
    {
      accessorKey: "groupEntreprise.name",
      header: "Groupe",
      size: 30,
      filterVariant: "multi-select",
      filterSelectOptions: groups,
    },
    {
      accessorKey: "prenom",
      header: "Prénom gérant",
      size: 30,
    },
    {
      accessorKey: "nom",
      header: "Nom gérant",
      size: 30,
    },
    {
      id: "created_at",
      accessorFn: (originalRow: any) => new Date(originalRow.created_at),
      header: "Date de création",
      filterVariant: "date-range",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
      type: "date",
    },
    {
      accessorKey: "adresse",
      header: "Adresse",
      size: 250,
    },
    {
      accessorKey: "mission",
      header: "Missions",
      enableColumnFilter: false,
      enableSorting: false,
      nocsv:true,
      Cell: ({ cell }: any) => {
        const id = cell.row.original._id;
        return (
          <Button
            onClick={() => {
              navigate("/missions?entreprise=" + id);
            }}
            className="btn btn-secondary btnsmall"
            title="Voir"
          />
        );
      },
    },
  ];
  useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const grps = (await axiosInstance.get("groups")).data.map((elt: any) => ({ value: elt.name, label: elt.name }));
        const secteurs = (await axiosInstance.get("secteurs")).data.map((elt: any) => ({
          value: elt.name,
          label: elt.name,
        }));
        setsecteurs(secteurs);
        setgroups(grps);
      } catch (e) {}
      setloading(false);
    })();
  }, []);
  return (
    <Table
      isLoading={loading}
      showExport
      dataUrl="users/entreprise"
      columns={columns}
      csvRows={csvRows}
      permission="entreprises"
      title="Entreprises"
    />
  );
}
