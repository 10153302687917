import React from "react";

import moment from "moment";
import Table from "../../components/table/table";

export default function MetierslistPage() {
  const columns = [
    {
      accessorKey: "name",
      header: "Nom",
    },
    {
      accessorKey: "secteur.name",
      header: "Secteur",
    },
  ];
  return <Table dataUrl="metiers" columns={columns} permission="metiers" title="Métiers" />;
}
