/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
import React, { useEffect, useState } from "react";
import FormLayout from "../../../components/formlayout/formlayout";
import axiosInstance from "../../../config/axios";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showToast } from "../../../utils/utils";
import SelectInput from "../../../components/form/inputs/select-input/select-input";
import { emailReg, passwordReg } from "../../../utils/regex";

export default function EditAddAdminPage() {
  const [loading, setIsLoading] = useState(false);
  const [notFound, setnotFound] = useState(false);
  const {  id } = useParams();
  const location = useLocation();
  const isAdd = location.pathname?.endsWith("add") ? true : false;
  const [user, setuser]: any = useState({});
  const navigate = useNavigate();

  async function onSubmit(form: any) {
    try {
      if (isAdd) {
        await axiosInstance.post("users/admin", form);
        showToast("Ajout avec succés", "success");
      } else {
        await axiosInstance.patch("users/admin/" + id, form);
        showToast("Modification bien enregistré", "success");
      }
      navigate(-1);
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Adresse e-mail prise par un autre compte");
    }
  }

  async function getData() {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get("users/admin" + "/" + id);
      setuser(data.data);
    } catch (e: any) {
      if (e.response?.status == 404) setnotFound(true);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (!isAdd) getData();
  }, [isAdd]);
  return (
    <FormLayout
      permission={"admins"}
      title="admininstrateur"
      img="/images/users.svg"
      onSubmit={onSubmit}
      loading={loading}
      notFound={notFound}
      isAdd={isAdd}
    >
      <div className="row" style={{ display: "flex", flexGrow: 1 }}>
        <div className="col-sm-12 col-md-6 ">
          <SelectInput
            options={[
              { label: "Validé", value: "valide" },
              { label: "En attente", value: "inscrit" },
              { label: "Inactive", value: "inactive" },
            ]}
            required="Statut invalide"
            value={user.status}
            name="status"
            label="Statut"
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
            name="email"
            label="E-mail"
            value={user.email}
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            autocomplete="new-password"
            validate={(val: any) =>
              ((val && !isAdd) || isAdd) && !passwordReg.test(val) ? "mot de passe invalide" : true
            }
            showPassword
            type="password"
            name="password"
            label="Mot de pase"
            placeHolder="*******"
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput
            validate={(val: any, form: any) =>
              form.password && (!val || val != form.password)
                ? "Le mot de passe n'est pas identique à celui saisi"
                : true
            }
            showPassword
            name="confirm"
            type="password"
            label="Confirmation de mot de pase"
            placeHolder="*******"
          />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput value={user.nom} required="Nom invalide" name="nom" label="Nom" />
        </div>
        <div className="col-sm-12 col-md-6 ">
          <TextInput value={user.prenom} required="Prénom invalide" name="prenom" label="Prénom" />
        </div>
      </div>
    </FormLayout>
  );
}
