/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Label from "../../label/label";
import "./date-time-input.css";
import { useController, useFormContext, UseControllerReturn, FieldValues } from "react-hook-form";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
dayjs.extend(utc);
const DateTimeInput = DateTimeInputController((props: DateTimeInputType) => {
  const [value, setValue]: any = useState("");
  useEffect(() => {
    setValue(props.value as any);
    props.controller?.field?.onChange(props.value);
  }, [props.value]);
  function onChange(val: any) {
    props.controller?.field?.onChange(val?.toDate());
    setValue(val?.toDate());
    if (props.onChange) props.onChange(val?.toDate());
  }
  const error = props.controller?.fieldState?.invalid;
  const errorMSg = props.controller?.fieldState?.error?.message;

  return (
    <div
      style={props.containerStyle}
      className={`datetime input-container ${props.inline ? "row" : ""} ${props.containerClass || ""}`}
    >
      <Label
        label={props.label}
        for={props.inputId}
        labelIcon={props.labelIcon}
        labelStyle={props.labelStyle}
        labelClass={props.labelClass}
      />
      <div>
        {props.type == "datetime" ? (
          <DateTimePicker
            format="DD/MM/YYYY HH:mm"
            value={value ? dayjs(value) : undefined}
            minDate={props.min ? dayjs(props.min) : undefined}
            maxDate={props.max ? dayjs(props.max) : undefined}
            minTime={props.maxTime ? dayjs(props.minTime) : undefined}
            onError={(error) => props.controller?.field?.onChange({ error: error })}
          
            ampm={false}
            className={error ? "dateerror" : ""}
            maxTime={props.maxTime ? dayjs(props.maxTime) : undefined}
            minDateTime={props.minDateTime ? dayjs(props.minDateTime) : undefined}
            
            maxDateTime={props.maxDateTime ? dayjs(props.maxDateTime) : undefined}
            disabled={props.disabled}
            timezone="Europe/Paris"
            onChange={onChange}
          
          />
        ) : props.type == "time" ? (
          <TimePicker
            minTime={props.min ? dayjs(props.minTime) : undefined}
            maxTime={props.max ? dayjs(props.maxTime) : undefined}
            timezone="Europe/Paris"
            ampm={false}
            onChange={onChange}
            disabled={props.disabled}
            value={value ? dayjs(value) : undefined}
          />
        ) : (
          <DatePicker
            format="DD/MM/YYYY"
            label={props.dateLabel}
            onChange={onChange}
            disabled={props.disabled}
            minDate={props.min ? dayjs(props.min) : undefined}
            maxDate={props.max ? dayjs(props.max) : undefined}
            value={value ? dayjs(value) : undefined}
          />
        )}
      </div>
      {error ? (
        <div className={`form-error-msg`}>
          <img
            style={{ marginInlineEnd: 5, marginTop: -3, marginRight: 5, width: 14, height: 14 }}
            src="/images/error.svg"
            alt=""
          />
          {errorMSg}
        </div>
      ) : null}
    </div>
  );
});
export default DateTimeInput;
function DateTimeInputController(Input: any) {
  return function (props: DateTimeInputType) {
    return InputControl(Input, props);
  };
}
function InputControl(Input: any, p: DateTimeInputType) {
  const formContext = useFormContext();
  let controller: any;
  if (p.name && formContext)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    controller = useController({
      name: p.name || "",
      rules: {
        required: p.required,
        validate: (val: any) => {
          if (val.error) return p.label + " invalide";
          return true;
        },
      },
      defaultValue: p.value,
    });
  return <Input {...p} controller={controller} />;
}
export interface DateTimeInputType {
  label?: string;
  labelIcon?: string | { className: string; style?: React.CSSProperties };
  labelClass?: string;
  labelStyle?: React.CSSProperties;
  type?: "date" | "datetime" | "time";
  min?: Date;
  max?: Date;
  minTime?: Date;
  maxTime?: Date;
  minDateTime?: Date;
  maxDateTime?: Date;
  value?: Date;
  validate?: Function;
  step?: string;
  inputClass?: string;
  inputStyle?: React.CSSProperties;
  inputId?: string;
  name?: string;
  pattern?: string;
  dateLabel?: string;
  form?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  controller?: UseControllerReturn<FieldValues, string>;
  required?: boolean | string;
  inline?: boolean;
  errorMsg?: string;
  errorMode?: "tooltip" | "feedback";
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  onChange?: Function;
}
